import React, { useState, useEffect } from "react";
import { withTranslation } from "react-i18next";
import {
    Row, Col, Label, Button,
    Card, CardBody, InputGroup, FormFeedback, Form
    , Input, Modal, ModalHeader, ModalBody,
    FormGroup
} from "reactstrap";
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import ".././../assets/css/custom.css";
import { useFormik } from "formik";
import '../reports/reportlist.scss'
import axios from "axios"
import {
    Container
} from "reactstrap";
import ".././../assets/css/custom.css";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import moment from 'moment';
import { API, ENDPOINT } from '../Authentication/api/index';

import { getLoggedInUser, getRefreshToken } from "../../helpers/fakebackend_helper"
import Select from "react-select";
import 'react-quill/dist/quill.snow.css';
import * as Yup from "yup";
import * as constants from "../../constants/layout"
import Spinners from "../../components/Common/Spinner";

import avatar from ".././../assets/images/reportentry_images/man_avatar.jpg";
import girlAvatar from ".././../assets/images/reportentry_images/female_avatar.jpg";
import transAvatar from ".././../assets/images/transgender.jpg";
import { size } from "lodash";
import Logout from "pages/Authentication/Logout";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const TemplateCreation = () => {

    const navigate = useNavigate();

    const user = getLoggedInUser();
    const { rptid } = useParams();

    const [modelHospitalName, setModelHospitalName] = useState('');
    const [label, setLabel] = useState('Hospital Name');
    const [bold, setBold] = useState(false);
    const [modal, setModal] = useState(false);
    const [editFlag, setEditFlag] = useState(false);
    const [isButtonDisabled, setButtonDisabled] = useState(false);

    const [modelHeader, setModelHeader] = useState('Add Hospital');

    const [statusFlag1, setStatusFlag1] = useState();
    const [hospital, setHospital] = useState([]);
    const [hpfilter, setHpFilter] = useState([]);
    const [drfilter, setDrFilter] = useState([]);
    const [isLoading, setLoading] = useState(false)
    const [reffererOptions, setReffererOptions] = useState([]);
    const [selectedHospital, setselectedhospital] = useState({ label: 'Select', id: 0 });
    const [selectedDate, setSelectedDate] = useState(new Date()); // Initialize state with current date
    const [statusFlag, setStatusFlag] = useState();
    const [ptid, setPtid] = useState();

    const [patient, setPatient] = useState([]);
    const [allpatient, setAllPatient] = useState([]);
    const [existmodal, setExistModal] = useState(false);
    const [filteredOptions, setFilteredOptions] = useState([]);
    const [searchInput, setSearchInput] = useState('');
    const [doctor, setDoctor] = useState([]);
    const [newmodal, setNewModal] = useState(false);
    const [reportEntryList, setReportEntryList] = useState(false);
    const [address, setAddress] = useState("");
    const [newcity, setnewCity] = useState("");
    const [existcard, setExistCard] = useState(false);
    const [selectedUHIDPATIENTEXIST, setselectedUHIDPATIENTEXIST] = useState(null);
    const [filteredData, setFiltered_data] = useState([]);
    const [selectedreferrername, setselectedReferrerName] = useState(null);
    const [patientID, setPatientID] = useState(0);
    const [genderMaster, setGenderMaster] = useState([
        { id: 1, g_name: "Male" },
        { id: 2, g_name: "Female" },
        { id: 3, g_name: "Transgender" }
    ])

    var filtered_data;
    let location = useLocation();
    var statusFlagEt;
    var editLoadData;
    useEffect(() => {
        getReportEntryList()
        getHospitalList()
        getPatientList()
        getReportNo()
        bindMasterDropdown()
    }, [])

    // Modal Open
    const toggle = () => {
        setModal(prev => !prev);
        resetForm()
    };



    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            patientname: '',
            uhid: '',
            selectedHospital: '',
            selectedreferrername: '',
            reportno: '',
            newpatientname: '',
            selectedNewHp: '',
            // selectedUHIDPATIENTEXIST: '',
            age: '',
            gender: '',
            phnum: '',
            address: '',
            city: '',
            reportDate: '',
            newpatient: '',
            existpatient: ''

        },
        validationSchema: Yup.object({
            patientname: Yup.string().required("Please enter patient name"),
            selectedHospital: Yup.object().required("Please select hospital"),
            selectedreferrername: Yup.object().required("Please select referrer name"),
            gender: Yup.string().required("Please select gender"),
            reportno: Yup.string().required("Please enter report no"),
            age: Yup.string().required('Please enter age'),
            phnum: Yup.string().matches(/^[0-9]+$/, "Must be only digits")
                .min(10, 'Must be exactly 10 digits')
                .max(10, 'Must be exactly 10 digits')
            // selectedUHIDPATIENTEXIST: Yup.object().required('Please select Patient')
        }),
        onSubmit: (values, { }) => {
            handleSaveClick()
        },
    });

    const handleSelectHospital = (selectedOption) => {
        // setselectedhospital(selectedOption);
        // const filtered_hospital = allpatient?.filter(e => Number(e?.pt_hospital_id) == Number(selectedOption?.value))
        // setPatient(filtered_hospital)
        const filtered_hospital = allpatient?.filter(e => Number(e?.pt_hospital_id) == Number(selectedOption?.value))
        setPatient(filtered_hospital)
        setselectedhospital(selectedOption);
    };
    const handleSelectReferrer = (selectedOption) => {
        setselectedReferrerName(selectedOption);
    };
    const textareachange = (event) => {
        setAddress(event.target.value);
    };
    const textareachangeCity = (event) => {
        setnewCity(event.target.value);
    };
    const customFilter = (option, inputValue) => {
        // Check if the option label starts with the input value (case-insensitive)
        return option.label.toLowerCase().startsWith(inputValue.toLowerCase());
    };
    const handleDateChange = (selectedDates) => {
        console.log(selectedDates);
        setSelectedDate(selectedDates); // Update selected date state
        validation.setFieldValue('reportDate', selectedDates); // Set form field value
    };
    const handleAgeChange = (event) => {
        const { value } = event.target;
        const maxLength = constants.textboxLength.age;

        // Ensure the value doesn't exceed the maximum length
        if (value.length <= maxLength) {
            validation.handleChange(event);
        } else {
            // Optionally, you can provide feedback or handle this case differently
            event.target.value = value.slice(0, maxLength);
            validation.setFieldValue("age", event.target.value);
        }
    };

    useEffect(() => {
        if (location?.state?.statusFlag) {
            setStatusFlag1(location?.state?.statusFlag)
            statusFlagEt = Number(location?.state?.statusFlag)
        }
        if (location?.state?.value) {
            editLoadData = location?.state?.value

            const gendername = genderMaster.filter(e => Number(e.id) == Number(editLoadData?.rpt_patient_data?.patient_gender
            ))
            console.log('editload', editLoadData);
            setEditFlag(true)
            validation.setFieldValue('gender', gendername[0]?.g_name)
            validation.setFieldValue('ptid', editLoadData?.rpt_patient_data?.ptid)
            setPatientID(editLoadData?.rpt_patient_data?.ptid)
            validation.setFieldValue('patientname', editLoadData?.rpt_patient_data?.patient_name)
            validation.setFieldValue('reportno', editLoadData?.rpt_report_no)
            validation.setFieldValue('rpt_id', editLoadData?.rpt_id)
            let dateObject = new Date(editLoadData?.rpt_report_date);
            setSelectedDate(dateObject)
            validation.setFieldValue('reportdate', dateObject)
            validation.setFieldValue('age', editLoadData?.rpt_patient_data?.patient_age)
            validation.setFieldValue('uhid', editLoadData?.rpt_patient_data?.patient_uhid)
            setAddress(editLoadData?.rpt_patient_data?.pt_address)
            validation.setFieldValue('address', editLoadData?.rpt_patient_data?.pt_address)
            validation.setFieldValue('city', editLoadData?.rpt_patient_data?.pt_city)
            setnewCity(editLoadData?.rpt_patient_data?.pt_city)
            validation.setFieldValue('phnum', editLoadData?.rpt_patient_data?.pt_contact_number)

            console.log('phnum', editLoadData?.rpt_patient_data?.pt_contact_number);

            validation.setFieldValue({
                selectedHospital: {
                    label: editLoadData?.rpt_hospital_data?.hospital_name,
                    value: editLoadData?.rpt_hospital_data?.hospital_id

                },
            });
            setselectedhospital({
                label: editLoadData?.rpt_hospital_data?.hospital_name,
                value: editLoadData?.rpt_hospital_data?.hospital_id
            });
            const event = {
                target: {
                    name: 'selectedHospital', value: {
                        label: editLoadData?.rpt_hospital_data?.hospital_name,
                        value: editLoadData?.rpt_hospital_data?.hospital_id
                    }
                }
            };
            validation.handleChange(event);

            validation.setFieldValue({
                selectedreferrername: {
                    label: editLoadData?.rpt_doctor_data?.referrer_name,
                    value: editLoadData?.rpt_doctor_data?.referrer_id

                },
            });
            setselectedReferrerName({
                label: editLoadData?.rpt_doctor_data?.referrer_name,
                value: editLoadData?.rpt_doctor_data?.referrer_id
            })
            const event1 = {
                target: {
                    name: 'selectedreferrername', value: {
                        label: editLoadData?.rpt_doctor_data?.referrer_name,
                        value: editLoadData?.rpt_doctor_data?.referrer_id
                    }
                }
            };
            validation.handleChange(event1);

        }
        else {
            statusFlagEt = 0
        }
    }, [location])



    const toggleModal = () => {
        setPtid(0)
        setFiltered_data([])
        setExistModal(false)
        setFilteredOptions([])
        setSearchInput('')
        setNewModal(true); // Toggle modal state
        validation.setFieldValue('gender', "")
        // validation.setFieldValue('ptid', "")
        // setPatientID(filtered_data[0]?.ptid)
        validation.setFieldValue('patientname', "")
        validation.setFieldValue('age', "")
        validation.setFieldValue('uhid', "")
        setAddress("")
        validation.setFieldValue('city', "")
        setnewCity("")
        validation.setFieldValue('phnum', "")
        validation.setFieldValue("selectedHospital", '');
        setselectedhospital("")
    };


    const genderChange = (e) => {
        validation.setFieldValue('gender', e?.target?.value)
    }

    const toggleExistModal = () => {
        validation.setFieldValue("selectedUHIDPATIENTEXIST", "");
        setselectedUHIDPATIENTEXIST("")
        validation.setFieldValue('gender', "")
        // validation.setFieldValue('ptid', "")
        // setPatientID(filtered_data[0]?.ptid)
        validation.setFieldValue('patientname', "")
        validation.setFieldValue('age', "")
        validation.setFieldValue('uhid', "")
        setAddress("")
        validation.setFieldValue('city', "")
        setnewCity("")
        validation.setFieldValue('phnum', "")
        validation.setFieldValue("selectedHospital", '');
        hospital.map((row) => {
            if (row.mv_default === 1)
                setselectedhospital({
                    label: row?.mv_value,
                    value: row?.mvid
                })
            validation.setFieldValue("selectedHospital", {
                label: row?.mv_value,
                value: row?.mvid
            });
        })
        // setselectedhospital("")

        setFiltered_data([])
        setNewModal(true)
        setExistModal(true); // Toggle modal state
    };


    const handleSaveClick = () => {
        saveReportData()

    }

    const handleSelectExistPatient = (selectedOption) => {
        setExistCard(true)
        setselectedUHIDPATIENTEXIST(selectedOption)
        filtered_data = patient.filter(e => e?.ptid == selectedOption?.value)
        validation.setFieldValue('gender', filtered_data[0]?.gender_name)
        validation.setFieldValue('ptid', filtered_data[0]?.ptid)
        setPatientID(filtered_data[0]?.ptid)
        validation.setFieldValue('patientname', filtered_data[0]?.pt_name)
        validation.setFieldValue('age', filtered_data[0]?.pt_age)
        validation.setFieldValue('uhid', filtered_data[0]?.pt_uhid)
        setAddress(filtered_data[0]?.pt_address)
        validation.setFieldValue('city', filtered_data[0]?.pt_city)
        setnewCity(filtered_data[0]?.pt_city)
        validation.setFieldValue('phnum', filtered_data[0]?.pt_contact_number != 0 ? filtered_data[0]?.pt_contact_number : "")

        filtered_data.sort((a, b) => a.pt_name.localeCompare(b.pt_name));
        setFiltered_data(filtered_data)
    };

    const handleuhidchanges = (inputValue) => {
        setSearchInput(inputValue);
        if (inputValue.length > 2) {
            const filtered = patient.filter(hp =>
                hp?.pt_name?.toLowerCase()?.includes(inputValue?.toLowerCase()?.trim()?.slice(0, 3)) ||
                hp?.pt_uhid?.toLowerCase()?.includes(inputValue?.toLowerCase()?.trim()?.slice(0, 3)) ||
                hp?.gender_name?.toLowerCase()?.includes(inputValue?.toLowerCase()?.trim()?.slice(0, 3))
            );

            if (filtered.length > 0) {
                const updatedFiltered = filtered.map(item => {
                    const gendername = genderMaster.find(e => Number(e.id) === Number(item.pt_gender));
                    return {
                        ...item,
                        gender_value: gendername ? gendername.g_name : ''
                    };
                });

                setFilteredOptions(updatedFiltered);
            } else {
                // Handle case where no matches are found
                setFilteredOptions([]);
            }
        } else {
            // Handle case where inputValue.length <= 2
            setFilteredOptions([]);
        }
    };

    const handlehpchanges = (inputValue) => {
        setSearchInput(inputValue);
        if (inputValue.length > 2) {
            const filtered = hospital?.filter(hp =>
                hp?.mv_value?.toLowerCase().includes(inputValue?.toLowerCase().trim().slice(0, 3))
            )
            setHpFilter(filtered)
        }
    };
    const handledrchanges = (inputValue) => {
      
        setSearchInput(inputValue);
    
        if (inputValue.length > 2) {
            const filtered = reffererOptions.filter(hp =>
                hp.mv_value.toLowerCase().includes(inputValue.toLowerCase().trim().slice(0, 3))
            )
            setDrFilter(filtered)
        }
    };


    async function saveReportData() {
        setLoading(true)
        // console.log('ptid', patientID);

        // // this.stateData = history.state;
        // console.log('State Data:', history.state);
    
        // console.log('selectedhospital', selectedHospital, validation.values.selectedHospital);

        var rpt_report_date = moment(selectedDate).format("YYYY-MM-DD");
        const gender = genderMaster.filter(e => e.g_name == validation?.values?.gender)
        var data = {
            "user_id": Number(user.uid),
            "rpt_report_date": rpt_report_date,
            "rpt_report_no": validation.values.reportno,
            "rpt_status": history.state?.usr?.rpt_status != undefined ? history.state?.usr?.rpt_status : 0,
            "rpt_echodiagram_data": {},
            "rpt_doopler_data": {},
            "rpt_color_doopler_data": {},
            "rpt_left_ventricle": {},
            "rpt_impression": {},
            "process_type": !existmodal ? "save" : "update",
            "rpt_update_id": rptid != 0 ? Number(rptid) : 0,
            "rpt_checker": Number(user.uid),
            "rpt_patient_data": {
                "ptid": patientID === undefined ? 0 : Number(patientID),
                "patient_name": validation?.values?.patientname,
                "patient_age": Number(validation?.values?.age),
                "patient_gender": gender[0]?.id,
                "patient_uhid": validation?.values?.uhid,
                "hospital_id": Number(validation?.values?.selectedHospital?.value),
                "hospital_name": validation?.values?.selectedHospital?.label,
            },
            "rpt_hospital_data": {
                "hospital_id": Number(validation?.values?.selectedHospital?.value),
                "hospital_name": validation?.values?.selectedHospital?.label
            },
            "rpt_doctor_data": {
                "referrer_id": Number(validation?.values?.selectedreferrername
                    ?.value),
                "referrer_name": validation?.values?.selectedreferrername
                    ?.label
            },
            "pt_name": validation?.values?.patientname,
            "pt_hospital_id": Number(validation?.values?.selectedHospital?.value),
            "pt_uhid": validation?.values?.uhid,
            "pt_age": Number(validation?.values?.age),
            "pt_gender": gender[0]?.id,
            "pt_address": address || "",
            "pt_city": newcity || "",
            "pt_contact_number": Number(validation?.values?.phnum) || '',
            "pt_stsid": 1,
            "ptid": patientID === undefined ? 0 : Number(patientID),
            "rpt_referrer_id": Number(validation?.values?.selectedreferrername?.value),
            "rpt_hospital_id": Number(validation?.values?.selectedHospital?.value),
            "rpt_patient_id": patientID === undefined ? 0 : Number(patientID)
        }
//   console.log('data', data);
//   return false
        const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };

        try {
            const response = await axios.post(process.env.REACT_APP_BASE_URL + ENDPOINT.REPORTSAVE, data, config);

            if (response && response?.data?.body?.statusFlag === 3) {
                toast.error(response?.data?.body?.message, { autoClose: 2000 });
                setTimeout(() => {
                    localStorage.removeItem("authUser");
                    navigate('/login')
                }, 2000);
            }

            if (response?.data?.body?.status === '401' || response?.data?.body?.status === 401) {
                const refresh = await getRefreshToken();

                if (refresh) {
                    if (Number(refresh) === constants.invalidRefreshToken) {
                        localStorage.removeItem("authUser");
                        navigate('/login')
                    } else {
                        await saveReportData();
                    }
                }
            }
            if (response) {

                if (response && response?.data?.body?.statusFlag === 1) {
                  
                    const urid = user?.user_previleges.find(e => e.u_urid == user.u_urid)
                    if (Number(urid?.u_urid) == 6) {
                        navigate('/report-list')
                    } 
                     if(history.state?.usr?.rpt_status != undefined && history.state?.usr?.rpt_status != null && history.state?.usr?.rpt_status == 1)
                        {
                         
                            navigate('/report-approval')   
                        }else {
                        navigate('/report-request')

                    }
                 
                    validation.resetForm()
                    validation.setFieldValue("selectedHospital", '');
                    validation.setFieldValue('selectedreferrername', '');
                    setselectedhospital('')
                    setselectedReferrerName('')
                    setAddress('')
                    setnewCity('')
                    toast.success(response?.data?.body?.message, { autoClose: 2000 });
                } else if (response && response?.data?.body?.statusFlag === 2) {
                    toast.error(response?.data?.body?.message, { autoClose: 2000 });
                } else if (response && response?.data?.body?.statusFlag === 4) {

                     if(history.state?.usr?.rpt_status != undefined && history.state?.usr?.rpt_status != null && history.state?.usr?.rpt_status == 1)
                        {
                            navigate('/report-approval')   
                        }
                        else
                        {
                            navigate('/report-request')
                        }
                  
                    toast.success(response?.data?.body?.message, { autoClose: 2000 });
                }
            
                else {
                    toast.error(response?.data?.body?.message, { autoClose: 2000 })
                }
            }
        } catch (error) {

            // Handle error
            setLoading(false)
            console.error(" Reportrequest save API Error:", error);
        }
    }
    const getReportEntryList = async (fildata) => {

        var varFromDate = new Date()
        var varToDate = new Date()
        if (fildata != undefined) {
            if (fildata[0].selectedDates != null) {
                varFromDate = fildata[0].selectedDates[0].toISOString().split('T')[0]
                varToDate = fildata[0].selectedDates[1].toISOString().split('T')[0]
            }
        }
        const data = {
            user_id: Number(user.uid),
            hospital_id: fildata !== undefined && fildata[0].selectedHospital !== '' ? String(fildata[0].selectedHospital.value) : String(0),
            patient_id: fildata !== undefined && fildata[0].selectedPatient !== '' ? String(fildata[0].selectedPatient.value) : String(0),
            referrer_id: fildata !== undefined && fildata[0].selectedDoctor !== '' ? String(fildata[0].selectedDoctor.value) : String(0),
            from_date: moment(varFromDate).format("YYYY-MM-DD"),
            to_date: moment(varToDate).format("YYYY-MM-DD"),
            rpt_status: [0, 3]
        };
        const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };
        try {
            const response = await axios.post(process.env.REACT_APP_BASE_URL + ENDPOINT.REPORTLIST, data, config);

            if (response && response?.data?.body?.statusFlag === 3) {
                toast.error(response?.data?.body?.message, { autoClose: 2000 });
                setTimeout(() => {
                    localStorage.removeItem("authUser");
                    navigate('/login')
                }, 2000);
            }

            if (response?.data?.body?.status === '401' || response?.data?.body?.status === 401) {
                const refresh = await getRefreshToken();

                if (refresh) {
                    if (Number(refresh) === constants.invalidRefreshToken) {
                        localStorage.removeItem("authUser");
                        navigate('/login')
                    } else {
                        await getReportEntryList();
                    }
                }
            }

            if (response?.data?.body?.reportEntryList) {
                setReportEntryList(response.data.body.reportEntryList);
            } else {
                toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
            }
        } catch (error) {
            // Handle error
            console.error("Report Entry API Error:", error);
        }
    }

    const getPatientList = async () => {
        // setList(data);
        setLoading(true)

        const data = {
            user_id: Number(user.uid)
        };
        const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };
        try {
            const response = await axios.post(process.env.REACT_APP_BASE_URL + API.DEFAULTMASTER + ENDPOINT.GETMASTERS, data, config);
            setLoading(false)

            if (response && response?.data?.body?.statusFlag === 3) {
                toast.error(response?.data?.body?.message, { autoClose: 2000 });
                setTimeout(() => {
                    localStorage.removeItem("authUser");
                    navigate('/login')
                }, 2000);
            }

            if (response?.data?.body?.status === '401' || response?.data?.body?.status === 401) {
                const refresh = await getRefreshToken();

                if (refresh) {
                    if (Number(refresh) === constants.invalidRefreshToken) {
                        localStorage.removeItem("authUser");
                        navigate('/login')
                    } else {
                        await getPatientList();
                    }
                }
            }
            if (response?.data?.body?.PatientList) {

                setPatient(response.data.body.PatientList);
                setAllPatient(response.data.body.PatientList);
                setDoctor(response.data.body.MasterList.filter(item => item.mv_mrid === 2));
                setReffererOptions(response.data.body.MasterList.filter(item => item.mv_mrid === 2));

            }
            else {
                toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
            }
        } catch (error) {
            // Handle error
            setLoading(false)
            console.error("Hospital master List API Error:", error);
        }
    }

    const getHospitalList = async () => {
        // setList(data);
        setLoading(true)

        const data = {
            user_id: Number(user.uid)
        };
        const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };
        try {
            const response = await axios.post(process.env.REACT_APP_BASE_URL + API.DEFAULTMASTER + ENDPOINT.GETHOSPITALMASTER, data, config);
            setLoading(false)

            if (response && response?.data?.body?.statusFlag === 3) {
                toast.error(response?.data?.body?.message, { autoClose: 2000 });
                setTimeout(() => {
                    localStorage.removeItem("authUser");
                    navigate('/login')
                }, 2000);

            }

            if (response?.data?.body?.status === '401' || response?.data?.body?.status === 401) {
                const refresh = await getRefreshToken();

                if (refresh) {
                    if (Number(refresh) === constants.invalidRefreshToken) {
                        localStorage.removeItem("authUser");
                        navigate('/login')
                    } else {
                        await getHospitalList();
                    }
                }
            }
            if (response?.data?.body?.hospitalMasterList) {
                setHospital(response.data.body.hospitalMasterList);

                response.data.body.hospitalMasterList.map((row) => {
                    if (row.mv_default === 1 && selectedHospital.id === 0 && !editLoadData) {
                        setselectedhospital({
                            label: row?.mv_value,
                            value: row?.mvid
                        })
                        validation.setFieldValue("selectedHospital", {
                            label: row?.mv_value,
                            value: row?.mvid
                        });
                    }
                })
            } else {
                toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
            }
        } catch (error) {
            // Handle error
            setLoading(false)
            console.error("Hospital master List API Error:", error);
        }
    }

    async function bindMasterDropdown() {


        const data = {
            user_id: Number(user.uid)
        };
        const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };
        try {
            const response = await axios.post(process.env.REACT_APP_BASE_URL + API.DEFAULTMASTER + "/" + ENDPOINT.GETMASTERS, data, config);


            if (response && response?.data?.body?.statusFlag === 3) {
                toast.error(response?.data?.body?.message, { autoClose: 2000 });
                setTimeout(() => {
                    localStorage.removeItem("authUser");
                    navigate('/login')
                }, 2000);

            }

            if (response?.data?.body?.status === '401' || response?.data?.body?.status === 401) {
                const refresh = await getRefreshToken();

                if (refresh) {
                    if (Number(refresh) === constants.invalidRefreshToken) {
                        localStorage.removeItem("authUser");
                        navigate('/login')
                    } else {
                        await bindMasterDropdown();
                    }
                }
            }

            if (response?.data?.body?.MasterList) {


                setReffererOptions(response.data.body.MasterList.filter(item => item.mv_mrid === 2))

            } else {
                toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
            }
        } catch (error) {
            // Handle error
            setLoading(false)
            console.error(" master List API Error:", error);
        }

    }
    // Initial Load
    useEffect(() => {
        if (rptid == 0) {

            document.getElementById('headerTitle').innerHTML = 'Create request';
            toggleModal()
        } else {
            document.getElementById('headerTitle').innerHTML = 'Edit Details';

        }
    }, []);


    // EditLoadApi Call
    useEffect(() => {
        if (rptid != "0" && rptid != null && rptid != undefined) {
        }

    }, [rptid])


    const masterValidation = useFormik({
        enableReinitialize: true,
        initialValues: {
            labelname: (modelHospitalName) || '',
            bold: (bold) || false,
        },
        validationSchema: Yup.object({
            labelname: Yup.string().required('Please enter ' + label.toLocaleLowerCase()),
        }),
        onSubmit: (values) => {
            const data = {
                user_id: Number(user.uid),
                rm_type: modelHeader === 'Add Hospital' ? 1 : 2,
                rm_value: modelHeader === 'Add Hospital' ? String(nameValidation(values.labelname)) : String(values.labelname.toUpperCase()),
                rm_bold: values.bold == true ? 1 : 0,
                rm_id: 0,
                rm_status: 1,
                process_type: 'save',
            }
            insertMasterData(data)
            setButtonDisabled(true)
        },
    });


    const resetForm = () => {
        setModal(false)
        // masterValidation.errors.labelname

        masterValidation.setTouched('labelname', false)

        masterValidation.setErrors('labelname', '')
        masterValidation.setFieldValue('labelname', '')
        masterValidation.errors.labelname === ''
        setModelHospitalName("")
    }

    const insertMasterData = async (data) => {
        try {
            const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };
            const response = await axios.post(process.env.REACT_APP_BASE_URL + ENDPOINT.REPORTMASTERSSAVE, data, config);
            setButtonDisabled(false)
            if (response) {

                if (response && response?.data?.body?.statusFlag === 3) {
                    toast.error(response?.data?.body?.message, { autoClose: 2000 });
                    setTimeout(() => {
                        localStorage.removeItem("authUser");
                        navigate('/login')
                    }, 2000);

                }

                if (response?.data?.body?.status === '401' || response?.data?.body?.status === 401) {
                    const refresh = await getRefreshToken();

                    if (refresh) {
                        if (Number(refresh) === constants.invalidRefreshToken) {
                            localStorage.removeItem("authUser");
                            navigate('/login')
                        } else {
                            await insertMasterData(data);
                        }
                    }
                }

                setStatusFlag(response?.data?.body?.statusFlag)
                if (response && response?.data?.body?.statusFlag === 1) {
                    toggle()
                    if (response?.data?.body?.hopitalDetails?.mv_mrid === 1) {
                        console.log(1);
                        setselectedhospital({
                            label: response?.data?.body?.hopitalDetails?.mv_value,
                            value: response?.data?.body?.hopitalDetails?.mvid
                        })
                        validation.setFieldValue("selectedHospital", { label: response?.data?.body?.hopitalDetails?.mv_value, value: response?.data?.body?.hopitalDetails?.mvid });
                    }
                    if (response?.data?.body?.hopitalDetails?.mv_mrid === 2) {
                        setselectedReferrerName({
                            label: response?.data?.body?.hopitalDetails?.mv_value,
                            value: response?.data?.body?.hopitalDetails?.mvid
                        })
                        validation.setFieldValue("selectedreferrername", { label: response?.data?.body?.hopitalDetails?.mv_value, value: response?.data?.body?.hopitalDetails?.mvid });
                    }
                    resetForm()
                    toast.success(response?.data?.body?.message, { autoClose: 2000 });
                    setHospital([{
                        label: response?.data?.body?.hopitalDetails?.mv_value,
                        value: response?.data?.body?.hopitalDetails?.mvid
                    }, ...hospital])
                    getHospitalList()
                    getPatientList()
                } else if (response && response?.data?.body?.statusFlag === 2) {
                    toast.error(response?.data?.body?.message, { autoClose: 2000 });
                    setModal(true)
                } else if (response && response?.data?.body?.statusFlag === 4) {
                    toggle()
                    console.log(response?.data?.body?.hopitalDetails);
                    if (response?.data?.body?.hopitalDetails?.mv_mrid === 1) {
                        console.log(1);
                        setselectedhospital({
                            label: response?.data?.body?.hopitalDetails?.mv_value,
                            value: response?.data?.body?.hopitalDetails?.mvid
                        })
                        validation.setFieldValue("selectedHospital", { label: response?.data?.body?.hopitalDetails?.mv_value, value: response?.data?.body?.hopitalDetails?.mvid });
                    }
                    if (response?.data?.body?.hopitalDetails?.mv_mrid === 2) {
                        setselectedReferrerName({
                            label: response?.data?.body?.hopitalDetails?.mv_value,
                            value: response?.data?.body?.hopitalDetails?.mvid
                        })
                        validation.setFieldValue("selectedreferrername", { label: response?.data?.body?.hopitalDetails?.mv_value, value: response?.data?.body?.hopitalDetails?.mvid });
                    }
                    getHospitalList()
                    getPatientList()
                    resetForm()
                    toast.success(response?.data?.body?.message, { autoClose: 2000 })
                }
                else {
                    toast.error(response?.data?.body?.message, { autoClose: 2000 })
                }
            }
        } catch (error) {
            setButtonDisabled(false)
            console.log('error in Add Master', error)
            toast.error("Master Added Failed", { autoClose: 2000 });
        }
    }

    const phoneNumberValidation = (event) => {
        const regex = /^[0-9]*$/;
        const input = event.target.value + event.key;
        if (!regex.test(input) || input.length > 10) {
            event.preventDefault();
        }
    };


    async function getReportNo() {
        const data = {
            user_id: Number(user.uid)
        };
        const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };
        try {
            const response = await axios.post(process.env.REACT_APP_BASE_URL + ENDPOINT.GETREPORTNO, data, config);

            if (response && response?.data?.body?.statusFlag === 3) {
                toast.error(response?.data?.body?.message, { autoClose: 2000 });
                setTimeout(() => {
                    localStorage.removeItem("authUser");
                    navigate('/login')
                }, 2000);

            }

            if (response?.data?.body?.status === '401' || response?.data?.body?.status === 401) {
                const refresh = await getRefreshToken();

                if (refresh) {
                    if (Number(refresh) === constants.invalidRefreshToken) {
                        localStorage.removeItem("authUser");
                        navigate('/login')
                    } else {
                        await getReportNo();
                    }
                }
            }

            if (response?.data?.body?.ReportNo) {


                // setReffererOptions(response.data.body.ReportNo.filter(item => item.mv_mrid === 2))
                // validation.values.reportno = response?.data?.body?.ReportNo
                validation.setFieldValue("reportno", response?.data?.body?.ReportNo);
            } else {
                toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
            }
        } catch (error) {
            // Handle error
            setLoading(false)
            console.error(" master List API Error:", error);
        }
    }

    const nameValidation = (value) => {
        var val = value.charAt(0).toUpperCase() + value.slice(1);
        return val;
    };

    const referrerValidation = (event) => {
        var val = event.target.value.toUpperCase();
        event.target.value = val;
    };

    return (
        <React.Fragment>
            <ToastContainer />
            <div className="page-content">
                <Container fluid>
                    {
                        isLoading ? <Spinners setLoading={setLoading} />
                            :

                            <Card>
                                <CardBody>
                                    <Form
                                        onSubmit={(e) => {
                                            e.preventDefault(); // Prevent default form submission
                                            validation.handleSubmit(); // Call Formik's handleSubmit
                                        }}
                                    >
                                        <Row className="row-cols-1 row-cols-sm-2">
                                            <Col className="col-lg-4 col-sm-12 mb-3 mt-2">
                                                <Label>Report No.<span style={{ color: 'red' }}>*</span></Label>
                                                <Input
                                                    name="reportno"
                                                    type="text"
                                                    placeholder="Report No."
                                                    validate={{ required: { value: true } }}
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.reportno || ""}
                                                    maxLength={constants.textboxLength.reportno}
                                                    readOnly
                                                    invalid={validation.touched.reportno && validation.errors.reportno}
                                                />
                                                {validation.touched.reportno && validation.errors.reportno ? (
                                                    <FormFeedback type="invalid">{validation.errors.reportno}</FormFeedback>
                                                ) : null}
                                            </Col>





                                            <Col className="col-lg-4 col-sm-12 mb-3 mt-2">
                                                <div className="mb-3">
                                                    <FormGroup className="mb-4">
                                                        <Label>Report Date</Label>
                                                        <InputGroup>
                                                            <Flatpickr
                                                                value={selectedDate}
                                                                className="form-control d-block"
                                                                // placeholder="dd M,yyyy"
                                                                options={{
                                                                    // altInput: true,
                                                                    // altFormat: "F j, Y",
                                                                    dateFormat: "d-m-Y",
                                                                }}
                                                                onChange={(dates) => {
                                                                    handleDateChange(dates[0]);
                                                                }}
                                                            />
                                                            <button className="btn btn-light " type="button" id="password-addon">
                                                                <i className="bx bx-calendar-event
"></i>
                                                            </button>
                                                        </InputGroup>
                                                        {/* <div>
                                                            Selected Dates: {selectedDates && selectedDates.length > 0 ? selectedDates.map(date => date.toLocaleDateString()).join(' - ') : 'Select a date range'}
                                                        </div> */}
                                                    </FormGroup>
                                                </div>
                                                {/* <Label>Report Date <span style={{ color: 'red' }}>*</span></Label>
                                                <InputGroup>
                                                    <Flatpickr
                                                        value={selectedDate}
                                                        options={{
                                                            altInput: true,
                                                            altFormat: "F j, Y",
                                                            dateFormat: "d/m/Y",
                                                        }}
                                                        onChange={(dates) => {
                                                            handleDateChange(dates[0]);
                                                        }}
                                                    />
                                                    <button className="btn btn-light" type="button" id="calendar-addon">
                                                        <i className="bx bx-calendar-event"></i>
                                                    </button>
                                                </InputGroup> */}
                                                {validation.errors.reportDate && (
                                                    <div className="invalid-feedback d-block">
                                                        {validation.errors.reportDate}
                                                    </div>
                                                )}
                                            </Col>
                                            <Col className="col-lg-4 col-sm-12 mt-1">
                                                <Label className="d-flex align-items-center hospital-label">
                                                    Referrer Name
                                                    <span style={{ color: 'red' }}>*
                                                        <span

                                                            onClick={() => {
                                                                setModal(true)
                                                                setLabel("Referrer Name")
                                                                setModelHeader("Add Referrer")
                                                            }}
                                                            type="button"
                                                            style={{
                                                                color: "white",
                                                                background: "transparent",
                                                                border: "transparent",
                                                                padding: "0px",
                                                                fontSize: "17px",
                                                                height: "25px"
                                                            }}
                                                        >
                                                            <i className="mdi mdi-plus-circle text-success"></i>
                                                        </span>
                                                    </span>
                                                </Label>
                                                <Select
                                                filterOption={customFilter} 
                                                    value={selectedreferrername}
                                                    onChange={(selectedOption) => {
                                                        handleSelectReferrer(selectedOption);
                                                        validation.setFieldValue("selectedreferrername", selectedOption);
                                                    }}
                                                    onInputChange={handledrchanges}

                                                    options={doctor.map((hp) => ({
                                                        label: hp.mv_value,
                                                        value: hp.mvid,
                                                    }))}
                                                    className={`select2-selection ${validation.touched.selectedreferrername && validation.errors.selectedreferrername ? 'is-invalid' : ''}`}
                                                    invalid={validation.touched.selectedreferrername && validation.errors.selectedreferrername}
                                                />
                                                {validation.touched.selectedreferrername && validation.errors.selectedreferrername ? (
                                                    <FormFeedback type="invalid">{validation.errors.selectedreferrername}</FormFeedback>
                                                ) : null}
                                            </Col>
                                        </Row>

                                        {/* <Row className="col-12">
                                            <Col className="col-12">


                                                {rptid != 0 ?

                                                    "" :


                                                    <Row>

                                                        <div className="d-flex justify-content-center mt-3">
                                                            <Col className="d-flex justify-content-end" md={6}>

                                                                <div className="form-check form-check-inline mx-2">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="radio"
                                                                        name="exampleRadios"
                                                                        id="exampleRadios1"
                                                                        value="option1"
                                                                        onClick={toggleModal}
                                                                        defaultChecked
                                                                    />
                                                                    <label
                                                                        className="form-check-label"
                                                                        htmlFor="exampleRadios1"
                                                                    >
                                                                        New Patient
                                                                    </label>
                                                                </div></Col>    <Col md={6}>
                                                                <div className="form-check form-check-inline mx-2">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="radio"
                                                                        name="exampleRadios"
                                                                        id="exampleRadios2"
                                                                        value="option2"
                                                                        onClick={toggleExistModal}
                                                                    />
                                                                    <label
                                                                        className="form-check-label"
                                                                        htmlFor="exampleRadios2"
                                                                    >
                                                                        Existing Patient
                                                                    </label>
                                                                </div>
                                                            </Col>

                                                        </div>

                                                    </Row>}
                                            </Col>
                                            <Col className="col-6">


                                            </Col>
                                            
                                        </Row> */}
                                        {/* {
                                            existmodal ? */}
                                        <Card className="card-bg">

                                            <Row className="row-cols-1 row-cols-md-2 g-3"> {/* Adjusts the number of columns based on screen size */}
                                                <Col>
                                                    <div className="mx-3 mb-3 report-request">
                                                        <Label>
                                                            Hospital
                                                            <span style={{ color: 'red' }}>*</span>
                                                            <span
                                                            
                                                                onClick={() => {
                                                                    setModal(true);
                                                                    setLabel("Hospital Name");
                                                                    setModelHeader("Add Hospital");
                                                                }}
                                                                type="button"
                                                                style={{
                                                                    color: "white",
                                                                    background: "transparent",
                                                                    border: "transparent",
                                                                    padding: "0px",
                                                                    fontSize: "17px",
                                                                    height: "25px",
                                                                    cursor: "pointer" // Adding cursor pointer to indicate clickable item
                                                                }}
                                                            >
                                                                <i className="mdi mdi-plus-circle text-success"></i>
                                                            </span>
                                                        </Label>
                                                        <Select
                                                        filterOption={customFilter} 
                                                            value={selectedHospital}
                                                            onChange={(selectedOption) => {
                                                                handleSelectHospital(selectedOption);
                                                                validation.setFieldValue("selectedHospital", selectedOption);
                                                            }}
                                                            onInputChange={handlehpchanges}
                                                            options={hospital.map((hp) => ({
                                                                label: hp.mv_value,
                                                                value: hp.mvid,
                                                            }))}
                                                            className={`select2-selection ${validation.touched.selectedHospital && validation.errors.selectedHospital ? 'is-invalid' : ''}`}
                                                            invalid={validation.touched.selectedHospital && validation.errors.selectedHospital}
                                                            isClearable // Allows clearing the selection
                                                        />
                                                        {validation.touched.selectedHospital && validation.errors.selectedHospital ? (
                                                            <FormFeedback type="invalid">{validation.errors.selectedHospital}</FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </Col>
                                                {editFlag === false && <Col>
                                                    <div className="mb-3 mx-2 mt-4">
                                                        <Label>UHID or Patient Name<span style={{ color: 'red' }}>*</span></Label>
                                                        <Select
                                                            id="hospital"
                                                            value={selectedUHIDPATIENTEXIST}
                                                            onChange={(selectedOption) => {
                                                                handleSelectExistPatient(selectedOption);
                                                                validation.setFieldValue("selectedUHIDPATIENTEXIST", selectedOption);
                                                            }}
                                                            onInputChange={handleuhidchanges}
                                                            options={filteredOptions.map(hp => ({
                                                                label: hp.pt_uhid && hp.pt_age ?
                                                                    `${hp.pt_name}, ${hp.pt_age} yrs, UHID: ${hp.pt_uhid}` :
                                                                    hp.pt_age && hp.gender_value ? `${hp.pt_name}, ${hp.pt_age} yrs ${hp.gender_value}`
                                                                        : hp.pt_uhid && hp.gender_value ?
                                                                            `${hp.pt_name}, UHID: ${hp.pt_uhid}, ${hp.gender_value}` :
                                                                            hp.pt_uhid ? `${hp.pt_name}, UHID: ${hp.pt_uhid}` :
                                                                                hp.pt_age ? `${hp.pt_name}, ${hp.pt_age} yrs` :
                                                                                    `${hp.pt_name}, ${hp.pt_age} yrs`,
                                                                value: hp.ptid,
                                                            }))}
                                                            className={`select2-selection ${validation.touched.selectedUHIDPATIENTEXIST && validation.errors.selectedUHIDPATIENTEXIST ? 'is-invalid' : ''}`}
                                                            placeholder="Search by UHID or patient"
                                                            isClearable // Allows clearing the selection
                                                        />
                                                        {validation.touched.selectedUHIDPATIENTEXIST && validation.errors.selectedUHIDPATIENTEXIST ? (
                                                            <FormFeedback type="invalid">{validation.errors.selectedUHIDPATIENTEXIST}</FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </Col>}
                                            </Row>
                                            {/* <Row className="row-cols-1">
                                                        <Col className="col-3">
                                                            <div className="mx-3 mb-3 report-request">
                                                                <Label>
                                                                    Hospital<span style={{ color: 'red' }}>*
                                                                        <span
                                                                            // color="success"
                                                                            onClick={() => {
                                                                                setModal(true)
                                                                                setLabel("Hospital Name")
                                                                                // setModelHospitalName("Hospital Name")
                                                                                setModelHeader("Add Hospital")
                                                                            }}
                                                                            type="button"
                                                                            style={{
                                                                                color: "white",
                                                                                background: "transparent",
                                                                                border: "transparent",
                                                                                padding: "0px",
                                                                                fontSize: "17px",
                                                                                height: "25px"
                                                                            }}
                                                                        >
                                                                            <i className="mdi mdi-plus-circle text-success"></i>
                                                                        </span></span>
                                                                </Label>
                                                                <Select
                                                                    value={selectedHospital}
                                                                    onChange={(selectedOption) => {
                                                                        handleSelectHospital(selectedOption);
                                                                        validation.setFieldValue("selectedHospital", selectedOption);
                                                                    }}
                                                                    onInputChange={handlehpchanges}
                                                                    options={hpfilter.map((hp) => ({
                                                                        label: hp.mv_value,
                                                                        value: hp.mvid,
                                                                    }))}
                                                                    className={`select2-selection ${validation.touched.selectedHospital && validation.errors.selectedHospital ? 'is-invalid' : ''}`}
                                                                    invalid={validation.touched.selectedHospital && validation.errors.selectedHospital}
                                                                />
                                                                {validation.touched.selectedHospital && validation.errors.selectedHospital ? (
                                                                    <FormFeedback type="invalid">{validation.errors.selectedHospital}</FormFeedback>
                                                                ) : null}
                                                            </div>
                                                        </Col>
                                                        <Col className="col-6">
                                                            <div className="mb-3 mx-2 mt-4">
                                                                <Label>UHID or Patient Name<span style={{ color: 'red' }}>*</span></Label>
                                                                <Select
                                                                    id="hospital"
                                                                    value={selectedUHIDPATIENTEXIST}
                                                                    onChange={(selectedOption) => {
                                                                        handleSelectExistPatient(selectedOption);
                                                                        validation.setFieldValue("selectedUHIDPATIENTEXIST", selectedOption);
                                                                    }}
                                                                    onInputChange={handleuhidchanges}
                                                                    options={filteredOptions.map(hp => ({
                                                                        label: hp.pt_uhid && hp.pt_age ?
                                                                            `${hp.pt_name}, ${hp.pt_age} yrs, UHID: ${hp.pt_uhid}` :
                                                                            hp.pt_age && hp.gender_value ? `${hp.pt_name}, ${hp.pt_age} yrs ${hp.gender_value}`
                                                                                : hp.pt_uhid && hp.gender_value ?
                                                                                    `${hp.pt_name}, UHID: ${hp.pt_uhid}, ${hp.gender_value}` :
                                                                                    hp.pt_uhid ? `${hp.pt_name}, UHID: ${hp.pt_uhid}` :
                                                                                        hp.pt_age ? `${hp.pt_name}, ${hp.pt_age} yrs` :
                                                                                            `${hp.pt_name}, ${hp.pt_age} yrs`
                                                                        ,
                                                                        value: hp.ptid,
                                                                    }))}
                                                                    className={`select2-selection ${validation.touched.selectedUHIDPATIENTEXIST && validation.errors.selectedUHIDPATIENTEXIST ? 'is-invalid' : ''}`}
                                                                    placeholder="Search by UHID or patient"
                                                                />
                                                                {validation.touched.selectedUHIDPATIENTEXIST && validation.errors.selectedUHIDPATIENTEXIST ? (
                                                                    <FormFeedback type="invalid">{validation.errors.selectedUHIDPATIENTEXIST}</FormFeedback>
                                                                ) : null}
                                                            </div>

                                                        </Col>
                                                    </Row> */}
                                        </Card>

                                        {
                                            newmodal || rptid != 0 ?
                                                <Card className="card-bg mt-3">
                                                    <Row className="row-cols-1 row-cols-sm-2">
                                                        <Col className="col-lg-3 col-sm-12">
                                                            <div className="mb-3 mt-4 mx-3">
                                                                <Label>Patient Name<span style={{ color: 'red' }}>*</span></Label>
                                                                <Input
                                                                    name="patientname"
                                                                    type="text"
                                                                    maxLength={constants.textboxLength.pt_name}
                                                                    placeholder="Patient Name"
                                                                    onChange={validation.handleChange}
                                                                    onBlur={validation.handleBlur}
                                                                    autoComplete="off"
                                                                    value={constants.capitalize(validation.values.patientname)}
                                                                    invalid={validation.touched.patientname && !!validation.errors.patientname}
                                                                />
                                                                {validation.touched.patientname && validation.errors.patientname ? (
                                                                    <FormFeedback type="invalid">{validation.errors.patientname}</FormFeedback>
                                                                ) : null}
                                                            </div>
                                                        </Col>
                                                        <Col className="col-lg-3 col-sm-12">
                                                            <div className="mb-3 mx-3 mt-4">
                                                                <Label>UHID</Label>
                                                                <Input
                                                                    name="uhid"
                                                                    type="text"
                                                                    placeholder="UHID"
                                                                    autoComplete="off"
                                                                    maxLength={constants.textboxLength.uhid}
                                                                    onChange={validation.handleChange}
                                                                    onBlur={validation.handleBlur}
                                                                    value={validation.values.uhid || ""}
                                                                    invalid={validation.touched.uhid && !!validation.errors.uhid}
                                                                />
                                                                {validation.touched.uhid && validation.errors.uhid ? (
                                                                    <FormFeedback type="invalid">{validation.errors.uhid}</FormFeedback>
                                                                ) : null}
                                                            </div>
                                                        </Col>
                                                        <Col className="col-lg-3 col-sm-12">
                                                            <div className="mb-3 mx-3 mt-4">
                                                                <Label>Age<span style={{ color: 'red' }}>*</span></Label>
                                                                <Input
                                                                    name="age"
                                                                    // maxLength={constants.textboxLength.age}
                                                                    type="number"
                                                                    autoComplete="off"
                                                                    placeholder="Age"
                                                                    onChange={handleAgeChange}

                                                                    // onChange={validation.handleChange}
                                                                    onBlur={validation.handleBlur}
                                                                    value={validation.values.age || ""}
                                                                    invalid={validation.touched.age && !!validation.errors.age}
                                                                />
                                                                {validation.touched.age && validation.errors.age ? (
                                                                    <FormFeedback type="invalid">{validation.errors.age}</FormFeedback>
                                                                ) : null}
                                                            </div>
                                                        </Col>
                                                        <Col className="col-lg-3 col-sm-12">
                                                            <div className="mx-3 mb-3 mt-3">
                                                                <Label className="d-block mb-3">Gender<span style={{ color: 'red' }}>*</span></Label>


                                                                <div className="d-flex">
                                                                    <div className="form-check form-check-inline">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="radio"
                                                                            name="gender"
                                                                            id="exampleRadios3"
                                                                            value="Male"
                                                                            onClick={genderChange}
                                                                            // defaultChecked={validation.values.gender === 'Male'}

                                                                            checked={validation.values.gender === "Male"}

                                                                        />
                                                                        <label
                                                                            className="form-check-label"
                                                                            htmlFor="exampleRadios3"
                                                                        >
                                                                            Male
                                                                        </label>
                                                                    </div>
                                                                    <div className="form-check form-check-inline">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="radio"
                                                                            name="gender"
                                                                            id="exampleRadios4"
                                                                            value="Female"
                                                                            // defaultChecked={validation.values.gender === 'Female'}

                                                                            checked={validation.values.gender === 'Female'}

                                                                            onClick={genderChange}
                                                                        />
                                                                        <label
                                                                            className="form-check-label"
                                                                            htmlFor="exampleRadios4"
                                                                        >
                                                                            Female
                                                                        </label>
                                                                    </div>
                                                                    <div className="form-check form-check-inline">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="radio"
                                                                            name="gender"
                                                                            id="exampleRadios5"
                                                                            value="Transgender"
                                                                            onClick={genderChange}
                                                                            // defaultChecked={validation.values.gender === 'Transgender'}

                                                                            checked={validation.values.gender === 'Transgender'}

                                                                        />
                                                                        <label
                                                                            className="form-check-label"
                                                                            htmlFor="exampleRadios5"
                                                                        >
                                                                            Transgender
                                                                        </label>
                                                                        {/* {validation.errors.gender}Error */}
                                                                    </div>
                                                                </div>

                                                            </div>
                                                            {/* {validation.touched.gender && validation.errors.gender ? (
                                                                <FormFeedback type="invalid">{validation.errors.gender}</FormFeedback>
                                                            ) : null} */}
                                                            <span className="text-danger" style={{ fontSize: '11px', marginLeft: '8%' }}> {validation.touched.gender && validation.errors.gender ? validation.errors.gender : ''}</span>
                                                        </Col>
                                                        <Col className="col-lg-3 col-sm-12">
                                                            <div className="mb-3 mt-3 mx-3">
                                                                <Label>City</Label>
                                                                <Input
                                                                    name="newCity"
                                                                    autoComplete="off"
                                                                    type="text"
                                                                    maxLength={constants.textboxLength.city}
                                                                    placeholder="City"
                                                                    onChange={e => {
                                                                        textareachangeCity(e)
                                                                    }}
                                                                    value={constants.capitalize(newcity)}

                                                                    onBlur={validation.handleBlur}
                                                                />
                                                            </div>
                                                        </Col>
                                                        <Col className="col-lg-3 col-sm-12">
                                                            <div className="mb-3 mx-3 mt-3">
                                                                <Label>Mobile Number</Label>
                                                                <Input
                                                                    name="phnum"
                                                                    type="text"
                                                                    autoComplete="off"
                                                                    maxLength={constants.textboxLength.ph_num}
                                                                    placeholder="Mobile Number"
                                                                    onChange={validation.handleChange}
                                                                    onBlur={validation.handleBlur}
                                                                    onKeyPress={(e) => phoneNumberValidation(e)}
                                                                    value={validation.values.phnum || ""}
                                                                    invalid={validation.touched.phnum && !!validation.errors.phnum}
                                                                />
                                                                {validation.touched.phnum && validation.errors.phnum ? (
                                                                    <FormFeedback type="invalid">{validation.errors.phnum}</FormFeedback>
                                                                ) : null}
                                                            </div>

                                                        </Col>
                                                        <Col className="col-lg-3 col-sm-12">
                                                            <div className="mb-3 mt-3 mx-3">
                                                                <Label>Address</Label>
                                                                <Input
                                                                    type="textarea"
                                                                    maxLength="225"
                                                                    rows="3"
                                                                    autoComplete="off"
                                                                    placeholder="Address"
                                                                    id="address"
                                                                    name="address"
                                                                    value={address}
                                                                    onChange={e => {
                                                                        textareachange(e)
                                                                    }}
                                                                    invalid={validation.touched.address && !!validation.errors.address}
                                                                />
                                                                {validation.touched.address && validation.errors.address ? (
                                                                    <FormFeedback type="invalid">{validation.errors.address}</FormFeedback>
                                                                ) : null}
                                                            </div>

                                                        </Col>
                                                    </Row>
                                                </Card>

                                                : ""


                                        }
                                        <Row>

                                            <Col>
                                                <div className="text-end">

                                                    <Button
                                                        color="success"
                                                        type="submit"
                                                        className="save-user  me-2"
                                                        onClick={
                                                            validation.handleSubmit
                                                            // handleSaveClick()
                                                        }

                                                    >
                                                        {rptid != 0 ? "Update" : "Save"}
                                                    </Button>
                                                    {"" && (
                                                        <Button
                                                            color="primary"
                                                            type="submit"
                                                            className="save-and-continue-user  me-2"
                                                            onClick={() => setSubmitbtn('savecontinue')}
                                                            style={{ marginLeft: '10px' }}
                                                            disabled={isButtonDisabled}

                                                        >
                                                            Save and Continue
                                                        </Button>
                                                    )}
                                                    <Button
                                                        color="danger"
                                                        type="submit"
                                                        className="save-and-continue-user me-2"
                                                        onClick={() => navigate('/report-request')}
                                                    // style={{ marginLeft: '10px' }}
                                                    // disabled={isButtonDisabled}

                                                    >
                                                        Close
                                                    </Button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Form>

                                </CardBody>
                            </Card>

                    }


                    <Modal isOpen={modal} toggle={toggle} backdrop="static">
                        <ModalHeader toggle={toggle} tag="h4">
                            {modelHeader}
                        </ModalHeader>
                        <ModalBody>
                            <Form
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    masterValidation.handleSubmit();
                                }}
                            >
                                <Row>
                                    <Col className="col-12">
                                        <div className="mb-3">
                                            <Label>{label}<span style={{ color: 'red' }}>*</span></Label>
                                            {modelHeader === 'Add Referrer' ?
                                                <InputGroup>
                                                    <div className="input-group-text">Dr.</div>
                                                    <Input
                                                        className="upper"
                                                        name="labelname"
                                                        type="text"
                                                        placeholder={label}
                                                        validate={{ required: { value: true } }}
                                                        maxLength="250"
                                                        onChange={masterValidation.handleChange}
                                                        // onBlur={masterValidation.handleBlur}
                                                        // onKeyUp={(e) => referrerValidation(e)}
                                                        value={constants.fullCaps(masterValidation.values.labelname) || ''}
                                                        invalid={masterValidation.touched.labelname && masterValidation.errors.labelname}
                                                        // innerRef={inputRef}
                                                        autoComplete="off"
                                                    />
                                                    {masterValidation.touched.labelname && masterValidation.errors.labelname ? (
                                                        <FormFeedback type="invalid">{masterValidation.errors.labelname}</FormFeedback>
                                                    ) : null}
                                                </InputGroup> :
                                                <Input
                                                    className="caps"
                                                    name="labelname"
                                                    type="text"
                                                    placeholder={label}
                                                    validate={{ required: { value: true } }}
                                                    maxLength="250"
                                                    onChange={masterValidation.handleChange}
                                                    // onBlur={masterValidation.handleBlur}
                                                    // onKeyUp={(e) => nameValidation(e)}
                                                    value={masterValidation.values.labelname || ''}
                                                    invalid={masterValidation.touched.labelname && masterValidation.errors.labelname}
                                                    // innerRef={inputRef}
                                                    autoComplete="off"

                                                />}
                                            {masterValidation.touched.labelname && masterValidation.errors.labelname ? (
                                                <FormFeedback type="invalid">{masterValidation.errors.labelname}</FormFeedback>
                                            ) : null}
                                        </div>
                                    </Col>
                                </Row>
                                {/* <Row>
                                    <Col className="col-6 text-end">
                                        <div className="form-check form-switch form-switch-md mb-3 me-2"><input type="checkbox" className="form-check-input" name="bold"
                                            onChange={masterValidation.handleChange} value={masterValidation.values.bold} id="customSwitchsizemd" /><label className="form-check-label">Bold</label></div>
                                    </Col>
                                </Row> */}
                                <Row>
                                    <Col>
                                        <div className="text-end">
                                            <Button
                                                color="success"
                                                type="submit"
                                                className="save-user"
                                                // onClick={() => masterValidation.handlesSubmit()}
                                                disabled={isButtonDisabled}
                                            >
                                                Save
                                            </Button>
                                            <Button
                                                color="secondary"
                                                className="save-and-continue-user"
                                                onClick={() => resetForm()}
                                                style={{ marginLeft: '10px' }}
                                            // disabled={isButtonDisabled}
                                            >
                                                Close
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </ModalBody>
                    </Modal>

                </Container>
            </div>
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </React.Fragment >
    );
};

export default withTranslation()(TemplateCreation);
