import PropTypes from "prop-types";
import React, { useEffect, useMemo, useState, useRef } from "react";
import {
    Row, Col, Label, Button, UncontrolledTooltip,
    Card, CardBody, FormGroup, InputGroup, FormFeedback, Form, Collapse,
} from "reactstrap";
import Select from "react-select";
import { API, ENDPOINT } from '../Authentication/api/index';
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import { useFormik } from "formik";
import Spinners from "../../components/Common/Spinner";

import TableContainer from '../../components/Common/TableContainer';
import * as Yup from "yup";
import '../../components/Common/common.scss'
//i18n
import { withTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
// const inputRef = useRef(null);
import DeleteModal from "../../components/Common/DeleteModal";
import './reportlist.scss'
import axios from "axios"
import { getLoggedInUser } from "../../helpers/fakebackend_helper"
import { getRefreshToken } from "../../helpers/fakebackend_helper";
import CommonFilter from "pages/Common/CommonFilter";
import moment from 'moment';
import * as constants from "../../constants/layout"
import Logout from "pages/Authentication/Logout";
import { toast, ToastContainer } from "react-toastify";
import {Workbook} from 'exceljs';
import * as FileSaver from 'file-saver';

import { PDFExport, savePDF } from '@progress/kendo-react-pdf';
import { BorderLeft, BorderRight, Padding } from "@mui/icons-material";
const ReportList = props => {
    //meta title
    
    const pdfExportComponent = useRef(null);
    document.title = "ReportList | Care AKPS";

    const navigate = useNavigate()

    const pstatus = [
        { id: 1, p_name: "Active" },
        { id: 2, p_name: "Inactive" },
        { id: 2, p_name: "Pending" }
    ]
    const userRole = [
        { id: 1, role_name: "Admin" },
        { id: 2, role_name: "Super Admin" }
    ]
    const [filterData, setFilterData] = useState({
        selectedHospital: 0,
        selectedHospital: 0 - 0,
        selectedHospital: 0,
        selectedHospital: 0,
        selectedHospital: 0,
    });
    const [isFilterOpen, setIsFilterOpen] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState(false);
    const [selectedPatient, setSelectedPatient] = useState(false);
    const [selectedDoctor, setSelectedDoctor] = useState(false);

    const [hospital, setHospital] = useState([]);
    const [doctor, setDoctor] = useState([]);
    const [patient, setPatient] = useState([]);


    const [isEdit, setIsEdit] = useState(false);
    const [selectedHospital, setselectedhospital] = useState(null);
    const [modal, setModal] = useState(false);
    const [canCreate, setCanCreate] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [isLoading, setLoading] = useState(false);
    // const [selectedDates, setSelectedDates] = useState([]);
    const [view, setView] = useState(false);
    const today = new Date();
    const [selectedDates, setSelectedDates] = useState([new Date(), new Date()]);
    const [defaultDate, setDefaultDate] = useState([new Date(), new Date()]);
    const flatpickrRef = React.useRef(null);
    const [reportList, setReportList] = useState(false);
    const user = getLoggedInUser();

    const [deleteTrigger, setDeleteTrigger] = useState(false)
    const [reportID, setReportID] = useState(0)
    const [showPDF, setShowPDF] = useState(false);

    var fileName;
    let header = 'Echo Cardiography Report';
    var today1 = new Date();
    var getdate =
    today1.getFullYear() +
      '-' +
      (today1.getMonth() + 1) +
      '-' +
      today1.getDate();
    var gettime =
    today1.getHours() +
      ':' +
      today1.getMinutes() +
      ':' +
      today1.getSeconds();

      fileName =  header + '_' + getdate + '_' + gettime + '.pdf',

      
    useEffect(() => {
        document.getElementById('headerTitle').innerHTML = 'Reports';
        setCanCreate(true)
        getReportEntryList()
    }, []);

    useEffect(() => {
        if (user?.user_previleges.filter(e => Number(e.u_urid) == Number(user?.u_urid) && Number(e.urp_mid) == 1 && Number(e.urp_smid) == 2)) {

        } else {
            localStorage.removeItem("authUser");
            navigate('/login')
        }
    }, [user])

    const onClickView = async (data) => {
        console.log('data', data)
        // navigate('/report-view')
        navigate('/report-view', { state: { rpt_id: data?.rpt_id } })
    }
        
    const onClickDelete = async (reportData) => {
        setReportID(reportData?.rpt_id);
        setDeleteModal(true);
    }
    
    const handleDeleteReport = () => {
        setDeleteTrigger(true)
        setLoading(true)
        const data = { user_id: Number(user.uid), rpt_id: Number(reportID) }

        deleteReport(data)
    };
  
    const exportExcel = () => {
        setShowPDF(true); // Show the PDFExport content
        var EXCEL_TYPE =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        // var Excel_Report = [];

        // Excel_Report = excelData.map((e, index) => {
        //     return {
        //         'S.No.': index + 1,
        //     }
        // });
        let workbook = new Workbook();
        let worksheet = workbook.addWorksheet('Echo Report List');

        setTimeout(() => {
            pdfExportComponent.current.save()?.then((data) => {
                const pdfBlob = new Blob([pdfExportComponent.current.value], { type: 'application/pdf' });
    
                // For example, you can create a URL and open it in a new tab
                const pdfUrl = URL.createObjectURL(pdfBlob);
                window.open(pdfUrl, '_blank');
           
        }); 
    
            setShowPDF(false); // Hide it again after export
            return false
        }, 0);
          
      

    //       var Excel_Report = [];
    //   Excel_Report = reportList.map((e, index) => {
    //     return [ index + 1,e.rpt_report_no,e.rpt_report_date,e.rpt_patient_data.patient_name,e.rpt_patient_data.patient_age,e.rpt_patient_data.patient_gender_value,e.rpt_doctor_data.referrer_name,e.rpt_hospital_data.hospital_name] 
      
    //   })

// worksheet.mergeCells('A1:H1'); // Merge cells for the title
// const titleRow = worksheet.getCell('A1');
// titleRow.value = "Echo Cardiography Report";
// titleRow.alignment = { horizontal: 'center', vertical: 'middle' };
// titleRow.font = { name: 'Calibri', size: 16, bold: true, color: { argb: '2e4167' } };

// worksheet.mergeCells('A2:B2'); // Merge Date column
// worksheet.mergeCells('C2:D2'); // Merge Hospital column
// worksheet.mergeCells('E2:F2'); // Merge Doctor column

// worksheet.getCell('A2').value = "Date : 12/";
// worksheet.getCell('C2').value = "Hospital:";
// worksheet.getCell('E2').value = "Doctor: Dr.Karthik";


// ['A2', 'C2', 'E2'].forEach((cell) => {
//   worksheet.getCell(cell).alignment = { horizontal: 'left', vertical: 'middle' };
//   worksheet.getCell(cell).font = { name: 'Calibri', size: 12 };
// });

// const headers = ['S.No.', 'Report No.', 'Report Date', 'Patient Name','Age','Gender','Doctor Name','Hospital Name'];
// const headerRow = worksheet.addRow(headers); // Add the header row


// headerRow.eachCell((cell) => {
//     cell.font = { bold: true, color: { argb: 'FFFFFFFF' } }; // Bold white text
//     cell.fill = {
//         type: 'pattern',
//         pattern: 'solid',
//         fgColor: { argb: 'FF4F81BD' }, // Background color (blue)
//     };
//     cell.alignment = { vertical: 'middle', horizontal: 'center' }; // Center alignment
// });
// // Sample data
// const data = [
//     [1, 'Item 1', 10, 20.5],
//     [2, 'Item 2', 5, 15.0],
//     [3, 'Item 3', 12, 30.0],
// ];


// Excel_Report.forEach(row => worksheet.addRow(row));


// worksheet.getRow(2).font = { bold: true }; // Second row contains headers
// worksheet.getRow(2).alignment = { vertical: 'middle', horizontal: 'center' };
// worksheet.columns.forEach((column) => {
//     column.width = 15; // Set column width
// });


//         workbook.xlsx.writeBuffer().then((data) => {
//             let blob = new Blob([data], {type: EXCEL_TYPE});
//             FileSaver.saveAs(
//               blob,
//               header + '_' + getdate + '_' + gettime + '.xlsx',
//             );
//           });
    };


    const deleteReport = async (data) => {
        try {
            const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };
            const response = await axios.post(process.env.REACT_APP_BASE_URL + ENDPOINT.REPORTDELETE, data, config);

            if (response && response?.data?.body?.statusFlag === 3) {
                toast.error(response?.data?.body?.message, { autoClose: 2000 });
                setTimeout(() => {
                    localStorage.removeItem("authUser");  
                     navigate('/login')
                 }, 2000);
            }

            if (response?.data?.body?.status === '401' || response?.data?.body?.status === 401) {
                const refresh = await getRefreshToken();

                if (refresh) {
                    if (Number(refresh) === constants.invalidRefreshToken) {
                        localStorage.removeItem("authUser");  
                         navigate('/login')
                    } else {
                        await deleteReport(data);
                    }
                }
            }

            if (response && response?.data?.body?.statusFlag === 1) {
                setDeleteModal(false)
                getReportEntryList()
                toast.success(response?.data?.body?.message, { autoClose: 2000 });
            } else {
                toast.error(response?.data?.body?.message, { autoClose: 2000 });
            }
        } catch (error) {
            toast.error("Report Deleted Failed", { autoClose: 2000 });
        }
    }


    const getReportEntryList = async (fildata) => {
        setLoading(true)
        setFilterData(fildata)
        var varFromDate = new Date()
        var varToDate = new Date()
        if (fildata != undefined) {
            if (fildata[0].selectedDates != null) {
                varFromDate = fildata[0].selectedDates[0]
                varToDate = fildata[0].selectedDates[1]
            }
        }

        var date_options = fildata !== undefined && fildata[0].DateOption !== '' && fildata[0].DateOption !== null && fildata[0].DateOption !== undefined ? fildata[0].DateOption : false

        var data = {
            user_id: Number(user.uid),
            hospital_id: fildata !== undefined && fildata[0].selectedHospital !== '' && fildata[0].selectedHospital?.value !== null && fildata[0].selectedHospital?.value !== undefined ? String(fildata[0].selectedHospital.value) : String(0),
            patient_id: fildata !== undefined && fildata[0].selectedPatient?.value !== '' && fildata[0].selectedPatient?.value !== null && fildata[0].selectedPatient?.value !== undefined ? String(fildata[0].selectedPatient.value) : String(0),
            referrer_id: fildata !== undefined && fildata[0].selectedDoctor?.value !== '' && fildata[0].selectedDoctor?.value !== null && fildata[0].selectedDoctor?.value !== undefined ? String(fildata[0].selectedDoctor.value) : String(0),
            from_date: moment(varFromDate).format("YYYY-MM-DD"),
            to_date: moment(varToDate).format("YYYY-MM-DD"),
            rpt_status: [2, 2],
            data_fetch: date_options === false ? 2 : 1
        };
        if (Number(user.u_urid) == 5) // For Client User
        {
            data = {
                user_id: Number(user.uid),
                hospital_id: String(user.u_hospital_id),
                patient_id: fildata !== undefined && fildata[0].selectedPatient !== '' ? String(fildata[0].selectedPatient.value) : String(0),
                referrer_id: fildata !== undefined && fildata[0].selectedDoctor !== '' ? String(fildata[0].selectedDoctor.value) : String(0),
                from_date: moment(varFromDate).format("YYYY-MM-DD"),
                to_date: moment(varToDate).format("YYYY-MM-DD"),
                rpt_status: [2, 2],
                data_fetch: date_options === false ? 2 : 1
            }
        }


        const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };
        try {
            const response = await axios.post(process.env.REACT_APP_BASE_URL + ENDPOINT.REPORTLIST, data, config);
            setLoading(false)

            if (response && response?.data?.body?.statusFlag === 3) {
                toast.error(response?.data?.body?.message, { autoClose: 2000 });

                setTimeout(() => {
                    localStorage.removeItem("authUser");
                    navigate('/login')
                }, 2000);
            }

            if (response?.data?.body?.status === '401' || response?.data?.body?.status === 401) {
                const refresh = await getRefreshToken();

                if (refresh) {
                    if (Number(refresh) === constants.invalidRefreshToken) {
                        localStorage.removeItem("authUser");
                        navigate('/login')
                    } else {
                        await getReportEntryList();
                    }
                }
            }

            if (response?.data?.body?.reportEntryList) {
                setReportList(response.data.body.reportEntryList);
            } else {
                toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
            }
        } catch (error) {
            // Handle error
            console.error("Report Entry API Error:", error);
        }
    }

    // Add click

    const handleAddClick = () => {
        navigate('/add-userrole', { state: { urid: '' } })
    };

    const handleEditClick = (userData) => {
        setModal(true)
        setIsEdit(true)
    };

    const handleDeleteUserRole = () => {
        setDeleteTrigger(true)
    };
    // Modal Open
    const toggle = () => {
        setModal(prev => !prev);
    };
    const toggleStatus = () => {
        setStatus(!status);
    };

    const handleApply = () => {
        setView(true)

        setFilterData({
            selectedHospital: selectedHospital ? selectedHospital.value : 0,
            selectedPatient: selectedPatient ? selectedPatient.value : 0,
            selectedSubjectName: selectedHospital ? selectedHospital.value : 0 - 0,
            selectedStatus: selectedStatus ? selectedStatus.value : 0,
            selectedExamPaper: selectedDates.map(date => date.toLocaleDateString()).join(' - ')

        })
    }

    const columns = useMemo(
        () => [
            {
                header: (
                    <div className="text-center" style={{ cursor: 'pointer' }}>
                        #
                    </div>
                ),
                accessorKey: 'sno',
                width: '8%',
                enableColumnFilter: false,
                enableSorting: true,
                className: 'pl-25',
                cell: ({ row, table }) => {

                    return (

                        <div className="text-center">
                            {(table.getSortedRowModel()?.flatRows?.findIndex((flatRow) => flatRow.id === row.id) || 0) + 1}
                        </div>
                    );
                }
            },
            {
                header: (
                    <div className="text-center" style={{ cursor: 'pointer' }}>
                        Report No. ▼
                    </div>
                ),

                accessorKey: 'rpt_report_no',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => {
                    const rpt_report_no = cellProps.row.original.rpt_report_no;

                    return (

                        <div className="text-center">

                            <Row>

                                <div>{rpt_report_no}</div>
                            </Row>

                        </div >
                    );
                },
            },
            {
                header: (
                    <div className="text-center" style={{ cursor: 'pointer' }}>
                        Report Date ▼
                    </div>
                ),

                accessorKey: 'rpt_report_date',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => {
                    const rpt_report_date = moment(cellProps.row.original.rpt_report_date).format("DD-MM-YYYY");

                    return (

                        <div className="text-center">

                            <Row>

                                <div>{rpt_report_date}</div>
                            </Row>

                        </div >
                    );
                },
            },
            {
                header: (
                    <div className="text-right" style={{ cursor: 'pointer' }}>
                        Patient Details ▼
                    </div>
                ),

                accessorKey: 'rpt_patient_data.patient_name',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => {
                    const patient = cellProps.row.original.rpt_patient_data.patient_name;
                    const age = cellProps.row.original.rpt_patient_data.patient_age;
                    const gender = cellProps.row.original.rpt_patient_data.patient_gender == 1 ? "Male" : "Female";
                    const uhid = cellProps.row.original.rpt_patient_data.patient_uhid;

                    return (
                        // <div className="d-flex align-items-center">
                        <div className="text-right">

                            <Row>

                                <div>{constants.fullCapsBold(patient)}</div>
                            </Row>
                            <Row>

                                <div>
                                    {age ? `${age}Yrs` : ''}
                                    {gender && ' | '}

                                    {gender ? `${gender}` : ''}
                                    {uhid && ' | '}

                                    {uhid ? `UHID:${uhid}` : ''}
                                </div>
                            </Row>

                        </div >
                    );
                },
            },
            {
                header: (
                    <div className="text-right" style={{ cursor: 'pointer' }}>
                        Doctor ▼
                    </div>
                ),

                accessorKey: 'rpt_doctor_data.referrer_name',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => {
                    const hospital_name = cellProps.row.original.rpt_hospital_data.hospital_name;
                    const doctor_name = cellProps.row.original.rpt_doctor_data.referrer_name;
                    return (
                        // <div className="d-flex align-items-center">
                        <div className="text-right">

                            <Row>
                                <div>{constants?.fullCapsBold('DR')}.{constants?.fullCapsBold(doctor_name)}</div>
                                <div>{hospital_name}</div>
                            </Row>

                        </div >
                    );
                },
            },
            {
                header: (
                    <div className="text-center" style={{ cursor: 'pointer' }}>
                        Approved By. ▼
                    </div>
                ),

                accessorKey: 'u_name',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => {
                    const approved_by = cellProps.row.original.u_name;

                    return (

                        <div className="text-center">

                            <Row>

                                <div>{approved_by}</div>
                            </Row>

                        </div >
                    );
                },
            },
            {
                header: (
                    <div className="text-center" style={{ cursor: 'pointer' }}>
                        Status ▼
                    </div>
                ),

                accessorKey: 'ur_stsid',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => {
                    const ur_stsid = cellProps.row.original.rpt_status;

                    return (

                        <div className="text-center">
                            <span style={{ width: 'auto' }} className="badge badge-soft-success rounded-pill  ms-1 font-size-12">

                                <i className="me-1" style={{ fontSize: '16px' }} />
                                {"Approved"}
                            </span>

                        </div>
                    );
                },
            },
            {
                header: (
                    <div className="text-center" style={{ cursor: 'pointer' }}>
                        Actions ▼
                    </div>
                ),
                // header: 'Actions',
                accessorKey: 'action',
                enableColumnFilter: false,
                enableSorting: true,

                width: '8%',
                // width: (cell) => cell.value.length * 10, 
                cell: (cellProps) => {
                    const id = cellProps.row.original.rpt_id;
                    return (
                        <div className="d-flex gap-3 justify-content-center" style={{ cursor: 'pointer' }}>

                            {/* <Button
                                to="#"
                                className="btn btn-sm btn-soft-info cust-btn pointer"
                                onClick={() => {
                                    const orderData = cellProps.row.original;
                                    handleEditClick(orderData);
                                }}
                            >
                                <i className="bx bxs-printer cust-i pointer" id={"printToolTip" + id} />
                                <UncontrolledTooltip placement="top" target={"printToolTip" + id}>
                                    Print
                                </UncontrolledTooltip>
                            </Button> */}


                            <div
                                to="#"
                                className="btn btn-sm btn-soft-info pointer"
                                onClick={() => {
                                    const orderData = cellProps.row.original;
                                    onClickView(orderData);
                                }}>
                                <i className="mdi mdi-eye pointer" id={"viewtooltip" + id} />
                                <UncontrolledTooltip placement="top" target={"viewtooltip" + id}>
                                    View{ user?.urid }
                                </UncontrolledTooltip>
                            </div>
{user?.u_urid == '2' || user?.u_urid == '4' ?
                            <div
                                to="#"
                                className="btn btn-sm btn-soft-danger pointer"
                                onClick={() => {
                                    const reportData = cellProps.row.original;             
                                    onClickDelete(reportData);
                                }}>
                                <i className="mdi mdi-delete pointer" id={"viewtooltip" + id} />
                                <UncontrolledTooltip placement="top" target={"viewtooltip" + id}>
                                    Delete
                                </UncontrolledTooltip>
                            </div>:null}




                        </div>




                        // <div className="text-center" style={{ cursor: 'pointer' }}>

                        //     <Button
                        //         to="#"
                        //         className="btn btn-sm btn-soft-info"
                        //         onClick={() => {
                        //             const orderData = cellProps.row.original;
                        //             handleEditClick(orderData);
                        //         }}
                        //     >
                        //         <i className="bx bxs-printer" id="printToolTip" />
                        //         <UncontrolledTooltip placement="top" target="printToolTip">
                        //             Print
                        //         </UncontrolledTooltip>
                        //     </Button>


                        // </div>
                    );
                }
            },

        ],
        []
    );


    const pageTemplate = (props) => {
        const { pageNum, totalPages } = props;
        return (
            <>            <div>
                <div style={{ position: 'absolute', top: '10px', bottom: '30px', width: '100%' }}>
                    <div style={{ textAlign: 'center' }}>
                       
                        <div style={{ textAlign: 'center',  marginTop: 36, marginBottom: 16, color: 'black',fontFamily:'DejaVu Sans Condensed Bold', }}>
                            <span style={{fontFamily:'DejaVu Sans Condensed Bold',
                                borderTop: '0.2px solid #0000000f', borderBottom: '0.2px solid #0000000f', fontSize: 11,   // Adjust padding to fit the text fully inside the border
                                display: 'inline-block',paddingRight:'6px !important',
                            }}>ECHO SUMMARY REPORT  </span> 

{filterData && filterData[0].selectedHospital?.label? <span style={{fontFamily:'DejaVu Sans Condensed Bold',
                                borderTop: '0.2px solid #0000000f', borderBottom: '0.2px solid #0000000f', fontSize: 11,   // Adjust padding to fit the text fully inside the border
                                display: 'inline-block',paddingRight:'6px !important', paddingTop:'3px !important'
                            }}>{'- '}{filterData[0].selectedHospital?.label} </span> :null}
                        </div>
                    </div>
             
                    {filterData && filterData[0].selectedDates?
                    <div className="row mt-1" style={{  color: 'black',marginLeft:20 }}>
                       <span style={{fontFamily:'DejaVu Sans Condensed Bold',
                               fontSize: 10,   // Adjust padding to fit the text fully inside the border
                                display: 'inline-block',paddingRight:'16px !important', paddingTop:'3px !important'
                            }}>Period : {filterData && filterData[0].selectedDates? filterData[0].selectedDates[0].toLocaleDateString("en-GB", {
                                day: '2-digit',
                                month: '2-digit',
                                year: 'numeric',
                            }) : null} - {filterData && filterData[0].selectedDates? filterData[0].selectedDates[1].toLocaleDateString("en-GB", {
                                day: '2-digit',
                                month: '2-digit',
                                year: 'numeric',
                            }) : null}</span> 
                  

                    </div>:<div className="row mt-1" style={{  color: 'black',marginLeft:20 }}>
                       <span style={{fontFamily:'DejaVu Sans Condensed Bold',
                               fontSize: 10,   // Adjust padding to fit the text fully inside the border
                                display: 'inline-block',paddingRight:'16px !important', paddingTop:'3px !important'
                            }}>Period : This month</span></div>}

                </div>
                <div className="pdf-footer">
                <Row style={{ fontSize: '7px' ,paddingLeft:16}}>
                Page {pageNum} of {totalPages}
            </Row>
              </div>
            </div>
            
            </>

        );
    };

    return (
        <React.Fragment>

            <DeleteModal
                show={deleteModal}
                onDeleteClick={handleDeleteReport}
                onCloseClick={() => setDeleteModal(false)}
            />
            <div className="page-content" style={{ height: '100%' }}>
                <div className="container-fluid">
                    <CommonFilter pageInd={'reportlist'} onFilter={getReportEntryList} />
                    
                    
                         
                    <Row>
                        {
                            isLoading ? <Spinners setLoading={setLoading} />
                                :
                                <Col xs="12">
                                    <Card>
                                        <CardBody>
                                            <TableContainer
                                                columns={columns}
                                                data={reportList || []}
                                                isGlobalFilter={true}
                                                isPagination={true}
                                                // isAddButton={canCreate}
                                                // buttonName="Add"
                                                SearchPlaceholder="Search..."
                                                pagination="pagination"
                                                handleUserClick={handleAddClick}
                                                // isCustomPageSize={true}
                                                //  paginationWrapper='dataTables_paginate paging_simple_numbers'

                                                buttonClass="btn btn-primary waves-effect waves-light mb-2 me-2 addOrder-modal"

                                                tableClass="align-middle table-nowrap dt-responsive nowrap w-100 table-check dataTable no-footer dtr-inline"
                                                theadClass="table-light"

                                                paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                                            />
                                        </CardBody>
                                    </Card>
                                </Col>
                        }
                    </Row>
                  
                </div>
                <div  onClick={() => exportExcel()} className="floating-download-icon">
                <i className="mdi mdi-download pointer"  />
    </div>
   
            </div >
            {showPDF && (
                <PDFExport style={{fontFamily:'DejaVu Sans Condensed !important'}}  forcePageBreak=".page-break" className=" page dejaVu-font" pageTemplate={pageTemplate} paperSize="A4" margin={{ top: 80, bottom: 40 }} ref={pdfExportComponent} fileName={fileName}>
                    <div className="printable" style={{padding:32}}>
        {reportList && reportList.length > 0?      <table style={{ borderCollapse: 'collapse',width: '100%', alignContent:'center' }}>
                    <thead style={{ display: "table-header-group !important" }}>
                        <tr >
                            <th width="20px !important"  style={headerStyle}>#</th>
                            <th width="60px !important" style={headerStyle}>Report No.</th>
                            <th width="60px !important" style={headerStyle}>Report Date</th>
                        
                            <th style={headerStyle}>Patient</th>
                            <th style={headerStyle}>Referred By</th>

                            <th width="60px !important" style={headerStyle}>Approved By</th>
                        </tr>
                    </thead>
                    <tbody>
                        {reportList?.map((row, index) => (
                            <tr key={index} style={{ borderBottom:"0.5px solid black !important",borderTop:"0.5px solid black !important"}}>
                                <td  width="20px !important" style={cellStyle}>{index + 1}</td>
                                <td width="60px !important"  style={cellStyle}>{row.rpt_report_no}</td>
                                <td width="60px !important"  style={cellStyle}>{new Date(row?.rpt_report_date).toLocaleDateString("en-GB", {
                                    day: '2-digit',
                                    month: '2-digit',
                                    year: 'numeric',
                                })}</td>
                            
                                <td style={cellStyle}><div>{row.rpt_patient_data.patient_name}</div><div style={{fontSize:8}}>{row?.rpt_patient_data.patient_age} {"yrs | "} {row?.rpt_patient_data.patient_gender_value}</div>
                                <div style={{ fontSize: 8 }}>
    {row?.rpt_patient_data?.patient_uhid 
        ? `UHID: ${row.rpt_patient_data.patient_uhid}` 
        : ""}
    </div></td>
                                <td style={cellStyle}><div>{row.rpt_doctor_data.referrer_name}</div><div style={{fontSize:8}}>{row.rpt_hospital_data.hospital_name}</div></td>
                                
                                <td width="60px !important"  style={cellStyle}>{row.u_name}</td>
                                
                            </tr>
                        ))}
                        {reportList?.length === 0 && (
                            <tr>
                                <td colSpan="3" style={{ textAlign: 'center' }}>
                                    No data available
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>:null}
                </div>
                </PDFExport>
             )}
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </React.Fragment >
    );
};

ReportList.propTypes = {
    t: PropTypes.any,
    chartsData: PropTypes.any,
    onGetChartsData: PropTypes.func,
};
const cellStyle = {
    border: '0.5px solid #c9cfc8',
  padding:4,
    textAlign: 'center',
    fontSize:10,
    fontFamily:'DejaVu Sans Condensed !important'
};
const headerStyle = {
    borderTop: '0.5px solid #c9cfc8',
    borderLeft: '0.5px solid #c9cfc8',
    borderRight: '0.5px solid #c9cfc8',
    padding:4,
    borderBottom:'none !important',
   backgroundColor:'rgba(18, 38, 63, 0.03)',
    textAlign: 'center',
    fontSize:10,
    fontFamily:'DejaVu Sans Condensed Bold !important'
};
export default withTranslation()(ReportList);
