import React, { useEffect, useMemo, useState, useRef } from "react";
import PropTypes from "prop-types";
//i18n
import { withTranslation } from "react-i18next";
import {
    Row, Col, Label, Button, UncontrolledTooltip,
    Card, CardBody, FormGroup, InputGroup, FormFeedback, Form, Collapse, Input
} from "reactstrap";
import Select from "react-select";

//Import Flatepicker
import "flatpickr/dist/themes/material_orange.css";
import Flatpickr from "react-flatpickr";
import { useFormik } from "formik";
import Spinners from "../../components/Common/Spinner";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import TableContainer from '../../components/Common/TableContainer';
import * as Yup from "yup";
import '../../components/Common/common.scss'
import { API, ENDPOINT } from '../Authentication/api/index';
import { getLoggedInUser, getRefreshToken } from "../../helpers/fakebackend_helper"
import axios from "axios"
import moment from 'moment';
import Logout from "pages/Authentication/Logout";

import { useNavigate } from "react-router-dom";
const CommonFilter = ({ onFilter,pageInd }) => {
    const navigate = useNavigate();
    const [isFilterOpen, setIsFilterOpen] = useState(false);
    const toggleFilter = () => setIsFilterOpen(!isFilterOpen);
    const [selectedDates, setSelectedDates] = useState([new Date(), new Date()]);
    const [isLoading, setLoading] = useState(false);
    const user = getLoggedInUser();
    const [patient, setPatient] = useState([]);

    const handleDateChange = (selectedDates) => {
        setSelectedDates(selectedDates);
    };
    const handleSelectHospital = (selectedOption) => {
        setselectedhospital(selectedOption);
    };
    const handleSelectPatient = (selectedOption) => {
        setSelectedPatient(selectedOption);
    };
    const handleSelectDoctor = (selectedOption) => {
        setSelectedDoctor(selectedOption);
    };

    const handleHospitalChange = (inputValue) => {
        setSearchInput(inputValue);
        if (inputValue.length > 2) {

            const filtered = hospital.filter(hp =>
                hp.mv_value?.toLowerCase().includes(inputValue?.toLowerCase().trim().slice(0, 3))
            );
            setFilteredOptions(filtered);
        }
    };
    const customFilter = (option, inputValue) => {
        // Check if the option label starts with the input value (case-insensitive)
        return option.label.toLowerCase().startsWith(inputValue.toLowerCase());
    };
    const handleDoctorChange = (inputValue) => {
        setSearchInput1(inputValue);
        // if (inputValue.length > 2) {

        const filtered = doctor.filter(hp =>
            hp.mv_value?.toLowerCase().includes(inputValue?.toLowerCase().trim().slice(0, 3))
        );
        setFilteredDrOptions(filtered);
        // }
    };

    const handlePatientChange = (inputValue) => {
        var filtered;
        setSearchInput2(inputValue);
        // if (inputValue.length > 2) {
        if (user?.u_urid == 5) {
            const data = patient.filter(e => Number(e.pt_hospital_id) == Number(user?.u_hospital_id))
            filtered = data.filter(hp =>
                hp.pt_name?.toLowerCase().includes(inputValue?.toLowerCase().trim().slice(0, 3))
                || hp.pt_uhid?.toLowerCase().includes(inputValue?.toLowerCase().trim().slice(0, 3))
                ||
                hp.gender_name?.toLowerCase().includes(inputValue?.toLowerCase().trim().slice(0, 3))
            );

        } else {

            filtered = patient.filter(hp =>
                hp.pt_name?.toLowerCase().includes(inputValue?.toLowerCase().trim().slice(0, 3))
                || hp.pt_uhid?.toLowerCase().includes(inputValue?.toLowerCase().trim().slice(0, 3))
                ||
                hp.gender_name?.toLowerCase().includes(inputValue?.toLowerCase().trim().slice(0, 3))
            );

        }
        setFilteredPatientOptions
            (filtered);
        // }
    };



    const [filteredOptions, setFilteredOptions] = useState([]);
    const [selectedDoctor, setSelectedDoctor] = useState('');
    const [selectedHospital, setselectedhospital] = useState('');
    const [selectedPatient, setSelectedPatient] = useState('');
    const [filteredDROptions, setFilteredDrOptions] = useState([]);
    const [filteredPatientOptions, setFilteredPatientOptions] = useState([]);
    const [searchInput1, setSearchInput1] = useState('');
    const [searchInput2, setSearchInput2] = useState('');
    const [searchInput, setSearchInput] = useState('');
    const [hospital, setHospital] = useState([]);
    const [doctor, setDoctor] = useState([]);
    const [status, setStatus] = useState(false);

    const handleApply = () => {

        var data = [{
            selectedDates: selectedDates || String(0),
            selectedHospital: selectedHospital || String(0),
            selectedDoctor: selectedDoctor || String(0),
            selectedPatient: selectedPatient || String(0),
            DateOption: status
        }];

        var filter_hp = hospital.find(hp => hp.mvid == user?.u_hospital_id)
        if (filter_hp) {
            data = [{
                selectedDates: selectedDates,
                selectedHospital: {
                    label: filter_hp.mv_value,
                    value: filter_hp.mvid
                },
                selectedDoctor: selectedDoctor,
                selectedPatient: selectedPatient,
                DateOption: status
            }]
        }
        console.log('date', data);
        console.log('status', status);
        onFilter(data);
    }

    const handleClear = () => {


        const data = [{
            selectedDates: [new Date(), new Date()],
            selectedHospital: '',
            selectedDoctor: '',
            selectedPatient: ''
        }];
        onFilter(data);
    }
    const handleDateClear = () => {


        const data = [{
            selectedDates: [new Date(), new Date()],
        }];
    }
    const handleHpClear = () => {


        const data = [{
            selectedHospital: '',
        }];
    }
    const handleDoctorClear = () => {


        const data = [{
            selectedDoctor: '',
        }];
    }
    const handlePatientClear = () => {


        const data = [{
            selectedPatient: ''
        }];
    }

    const getHospitalList = async () => {
        setLoading(true)

        const data = {
            user_id: Number(user.uid)
        };
        const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };
        try {
            const response = await axios.post(process.env.REACT_APP_BASE_URL + API.DEFAULTMASTER + ENDPOINT.GETHOSPITALMASTER, data, config);
            setLoading(false)

            if (response && response?.data?.body?.statusFlag === 3) {
                toast.error(response?.data?.body?.message, { autoClose: 2000 });
                setTimeout(() => {
                    localStorage.removeItem("authUser");
                    // window.location.reload();
                    navigate('/login')
                }, 2000);
            }

            if (response?.data?.body?.status === '401' || response?.data?.body?.status === 401) {
                const refresh = await getRefreshToken();

                if (refresh) {
                    if (Number(refresh) === constants.invalidRefreshToken) {
                        localStorage.removeItem("authUser");
                        // window.location.reload();
                        navigate('/login')
                    } else {
                        await getHospitalList();
                    }
                }
            }

            if (response?.data?.body?.hospitalMasterList) {
                setHospital(response.data.body.hospitalMasterList);
            } else {
                toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
            }
        } catch (error) {
            // Handle error
            setLoading(false)
            console.error("Hospital master List API Error:", error);
        }
    }

    const getPatientList = async () => {
        setLoading(true)

        const data = {
            user_id: Number(user.uid)
        };
        const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };
        try {
            const response = await axios.post(process.env.REACT_APP_BASE_URL + API.DEFAULTMASTER + ENDPOINT.GETMASTERS, data, config);
            setLoading(false)

            if (response && response?.data?.body?.statusFlag === 3) {
                toast.error(response?.data?.body?.message, { autoClose: 2000 });

                setTimeout(() => {
                    localStorage.removeItem("authUser");
                    // window.location.reload();
                    navigate('/login')
                }, 2000);

            }

            if (response?.data?.body?.status === '401' || response?.data?.body?.status === 401) {
                const refresh = await getRefreshToken();

                if (refresh) {
                    if (Number(refresh) === constants.invalidRefreshToken) {
                        localStorage.removeItem("authUser");
                        // window.location.reload();
                        navigate('/login')
                    } else {
                        await getPatientList();
                    }
                }
            }

            if (response?.data?.body?.PatientList) {
                setPatient(response.data.body.PatientList);
                setDoctor(response.data.body.MasterList.filter(item => item.mv_mrid === 2));

            } else {
                toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
            }
        } catch (error) {
            // Handle error
            setLoading(false)
            console.error("Hospital master List API Error:", error);
        }
    }

    console.log(moment(selectedDates[0]).format("DD-MM-YYYY"));


    // Initial Load
    useEffect(() => {
        getHospitalList()
        getPatientList()
    }, [])

    return (
        <Card>
            <CardBody>
                <Form>
                    <Row className="g-3">
                        <Col lg={8}>
                            {isFilterOpen === false && <><span style={{ fontWeight: 'bold' }}>Filter By </span><br />
                                Date: { status ? <span style={{ fontWeight: 'bold' }} className="me-2"> {moment(selectedDates[0]).format("DD-MM-YYYY")} - {moment(selectedDates[1]).format("DD-MM-YYYY")} </span> : pageInd == "reportlist"?"This month":"All Date"}
                                {selectedHospital !== '' && <span>| Hospital: <span style={{ fontWeight: 'bold' }} className="me-2">{selectedHospital.label} </span></span>}
                                {selectedDoctor !== '' && <span>| Doctor: <span style={{ fontWeight: 'bold' }} className="me-2">{selectedDoctor.label} </span> </span>}
                                {selectedPatient !== '' && <span>| Patient: <span style={{ fontWeight: 'bold' }}>{selectedPatient.label}</span></span>}</>}
                        </Col>
                        <Col lg={4} className="text-end">


                            <Row className="text-end">
                                {/* {user && user.user_previleges && user.user_previleges.find(f =>
                                                Number(f.urp_mid) === constants.mainMenuList.QA && f.urp_type === constants.menuTypes.Create) && ( */}

                                <div className='hstack gap-3' style={{ justifyContent: 'end' }}>


                                    <a href="#" onClick={toggleFilter} className="btn btn-secondary h-100">
                                        <i className="bx bx-filter-alt align-left"></i></a>
                                </div>

                            </Row>
                        </Col>


                        <Collapse isOpen={isFilterOpen} id="collapseExample">
                            <Row className="align-items-center">
                                {/* Date Range Picker */}
                                <Col lg={2}>
                                    <div className="mb-1">
                                        <div className="mb-1 mt-1">
                                            <Label>Date Options</Label>
                                            <div className="square1-switch">
                                                <input
                                                    type="checkbox"
                                                    id="statusToggle"
                                                    className="switch1 switch1-info"
                                                    // checked={status}
                                                    onClick={() => setStatus(!status)}
                                                />
                                                <label htmlFor="statusToggle" data-on-label="Customize Date" data-off-label={pageInd == "reportlist"?"This month":"All Date"} />
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                {status && <><Col lg={3}>
                                    <div className="mb-3">
                                        <FormGroup className="mb-4">
                                            <Label>Date Range</Label>
                                            <InputGroup>
                                                <Flatpickr
                                                    value={selectedDates}
                                                    className="form-control d-block"
                                                    options={{
                                                        mode: "range",
                                                        dateFormat: "d-m-Y",
                                                        onChange: handleDateChange // Attach onChange handler to update state
                                                    }}
                                                    isClearable={true} // Enable clear button
                                                />
                                                <button className="btn btn-light" type="button" id="calendar-addon">
                                                    <i className="bx bx-calendar-event"></i>
                                                </button>
                                            </InputGroup>
                                        </FormGroup>
                                    </div>
                                </Col>
                                    <Col lg={1}>
                                        <Button
                                            style={{ marginTop: 10 }}
                                            to="#"
                                            className="btn btn-sm btn-warning"
                                            onClick={() => {
                                                setSelectedDates([new Date(), new Date()]);
                                                handleDateClear();
                                            }}
                                        >
                                            <i className="mdi mdi-undo" id="clearToolTip" style={{ fontSize: '15px' }} />
                                            <UncontrolledTooltip placement="top" target="clearToolTip">
                                                Clear
                                            </UncontrolledTooltip>
                                        </Button>
                                    </Col></>}


                                {/* Hospital Select */}
                                {user?.u_urid !== "5" && (
                                    <Col lg={2}>
                                        <div className="mb-3">
                                            <label htmlFor="hospital">Hospital</label>
                                            <Select
                                            filterOption={customFilter} 
                                                id="hospital"
                                                placeholder='Search by Name'
                                                value={selectedHospital}
                                                onChange={(selectedOption) => {
                                                    handleSelectHospital(selectedOption);
                                                }}
                                                onInputChange={handleHospitalChange}
                                                options={hospital.map(hp => ({
                                                    label: hp.mv_value,
                                                    value: hp.mvid,
                                                }))}
                                                className="select2-selection"
                                                isClearable={true} // Enable clear button
                                            />
                                        </div>
                                    </Col>
                                )}

                                {/* Clear Button */}
                                {/* <Col lg={1}>
                                    <Button
                                        style={{ marginTop: 10 }}
                                        to="#"
                                        className="btn btn-sm btn-warning"
                                        onClick={() => {
                                            setselectedhospital('')
                                            handleHpClear();
                                        }}
                                    >
                                        <i className="mdi mdi-undo" id="clearToolTip" style={{ fontSize: '15px' }} />
                                        <UncontrolledTooltip placement="top" target="clearToolTip">
                                            Clear
                                        </UncontrolledTooltip>
                                    </Button>
                                </Col> */}

                                <Col lg={2}>
                                    <div className="mb-3">
                                        <Label>Doctor</Label>
                                        <Select
                                        filterOption={customFilter} 
                                            id="doctor"
                                            placeholder='Search by Name'
                                            value={selectedDoctor}
                                            onChange={(selectedOption) => {
                                                handleSelectDoctor(selectedOption);
                                            }}
                                            onInputChange={handleDoctorChange}
                                            options={doctor.map(hp => ({
                                                label: hp.mv_value,
                                                value: hp.mvid,
                                            }))}
                                            isClearable={true} // Enable clear button
                                            className={`select2-selection $`}
                                        // invalid={validation.touched.selectedDoctor && validation.errors.selectedDoctor}
                                        />

                                    </div>

                                </Col>
                                <Col lg={3}>
                                    <div className="mb-3">
                                        <Label>Patient</Label>
                                        <Select
                                            id="patient"
                                            placeholder='Search by UHID or Name'
                                            value={selectedPatient}
                                            onChange={(selectedOption) => {
                                                handleSelectPatient(selectedOption);
                                            }}
                                            onInputChange={handlePatientChange}
                                            // options={filteredPatientOptions.map(hp => ({
                                            //     label: hp.pt_name,
                                            //     value: hp.ptid,
                                            // }))}
                                            isClearable={true} // Enable clear button
                                            options={filteredPatientOptions.map(hp => ({
                                                label: hp.pt_uhid && hp.pt_age ?
                                                    `${hp.pt_name}, ${hp.pt_age} yrs, UHID: ${hp.pt_uhid}` :
                                                    hp.pt_age && hp.gender_value ? `${hp.pt_name}, ${hp.pt_age} yrs ${hp.gender_value}`
                                                        : hp.pt_uhid && hp.gender_value ?
                                                            `${hp.pt_name}, UHID: ${hp.pt_uhid}, ${hp.gender_value}` :
                                                            hp.pt_uhid ? `${hp.pt_name}, UHID: ${hp.pt_uhid}` :
                                                                hp.pt_age ? `${hp.pt_name}, ${hp.pt_age} yrs` :
                                                                    `${hp.pt_name}, ${hp.pt_age} yrs`
                                                ,
                                                // label: `${hp.pt_name}, ${hp.pt_age} yrs, UHID : ${hp.pt_uhid}, ${hp.gender_name}`,
                                                value: hp.ptid,
                                            }))}
                                            className={`select2-selection $`}
                                        // invalid={validation.touched.selectedPatient && validation.errors.selectedPatient}
                                        />
                                    </div>
                                </Col>
                                <Col lg={1}>

                                    <div className="d-flex gap-3">
                                        <Button
                                            style={{ marginTop: 25 }}
                                            to="#"
                                            className="btn btn-sm btn-success"
                                            onClick={handleApply}
                                        >
                                            <i className="mdi mdi-check-circle" style={{ fontSize: '15px' }} id="filterrooltip" />
                                            <UncontrolledTooltip placement="top" target="filterrooltip">
                                                View
                                            </UncontrolledTooltip>
                                        </Button>


                                        <Button
                                            style={{ marginTop: 25 }}
                                            to="#"
                                            className="btn btn-sm btn-warning"
                                            onClick={() => {

                                                setSelectedDates([new Date(), new Date()])
                                                setSelectedDoctor('')
                                                setselectedhospital('')
                                                setSelectedPatient('')
                                                handleClear()

                                            }

                                            }>
                                            <i className="mdi mdi-undo" id="clearToolTip" style={{ fontSize: '15px' }} />
                                            <UncontrolledTooltip placement="top" target="clearToolTip">
                                                Clear
                                            </UncontrolledTooltip>
                                        </Button>

                                    </div>
                                </Col>


                            </Row>
                            <Row>


                                {/* <Col lg={1}>
                                    <Button
                                        style={{ marginTop: 30 }}
                                        to="#"
                                        className="btn btn-sm btn-warning"
                                        onClick={() => {
                                            setSelectedDoctor('');
                                            handleDoctorClear();
                                        }}
                                    >
                                        <i className="mdi mdi-undo" id="clearToolTip" style={{ fontSize: '15px' }} />
                                        <UncontrolledTooltip placement="top" target="clearToolTip">
                                            Clear
                                        </UncontrolledTooltip>
                                    </Button>
                                </Col> */}

                                {/* <Col lg={3}>
                                    <div className="mb-3">
                                        <Label>Patient</Label>
                                        <Select
                                            id="patient"
                                            placeholder='Search by UHID or Name'
                                            value={selectedPatient}
                                            onChange={(selectedOption) => {
                                                handleSelectPatient(selectedOption);
                                            }}
                                            onInputChange={handlePatientChange}
                                            // options={filteredPatientOptions.map(hp => ({
                                            //     label: hp.pt_name,
                                            //     value: hp.ptid,
                                            // }))}
                                            isClearable={true} // Enable clear button
                                            options={filteredPatientOptions.map(hp => ({
                                                label: hp.pt_uhid && hp.pt_age ?
                                                    `${hp.pt_name}, ${hp.pt_age} yrs, UHID: ${hp.pt_uhid}` :
                                                    hp.pt_age && hp.gender_value ? `${hp.pt_name}, ${hp.pt_age} yrs ${hp.gender_value}`
                                                        : hp.pt_uhid && hp.gender_value ?
                                                            `${hp.pt_name}, UHID: ${hp.pt_uhid}, ${hp.gender_value}` :
                                                            hp.pt_uhid ? `${hp.pt_name}, UHID: ${hp.pt_uhid}` :
                                                                hp.pt_age ? `${hp.pt_name}, ${hp.pt_age} yrs` :
                                                                    `${hp.pt_name}, ${hp.pt_age} yrs`
                                                ,
                                                // label: `${hp.pt_name}, ${hp.pt_age} yrs, UHID : ${hp.pt_uhid}, ${hp.gender_name}`,
                                                value: hp.ptid,
                                            }))}
                                            className={`select2-selection $`}
                                        // invalid={validation.touched.selectedPatient && validation.errors.selectedPatient}
                                        />
                                    </div>
                                </Col> */}
                                {/* <Col lg={1}>
                                    <Button
                                        style={{ marginTop: 30 }}
                                        to="#"
                                        className="btn btn-sm btn-warning"
                                        onClick={() => {
                                            setSelectedPatient('');
                                            handlePatientClear();
                                        }}
                                    >
                                        <i className="mdi mdi-undo" id="clearToolTip" style={{ fontSize: '15px' }} />
                                        <UncontrolledTooltip placement="top" target="clearToolTip">
                                            Clear
                                        </UncontrolledTooltip>
                                    </Button>
                                </Col> */}

                            </Row>


                            {/* <Col lg={3}>
                                <div className="mb-3">
                                    <Label>Patient</Label>
                                    <Select
                                        id="patient"
                                        placeholder='Search by UHID or Name'
                                        value={selectedPatient}
                                        onChange={(selectedOption) => {
                                            handleSelectPatient(selectedOption);
                                        }}
                                        onInputChange={handlePatientChange}
                                        // options={filteredPatientOptions.map(hp => ({
                                        //     label: hp.pt_name,
                                        //     value: hp.ptid,
                                        // }))}
                                        options={filteredPatientOptions.map(hp => ({
                                            label: hp.pt_uhid && hp.pt_age ?
                                                `${hp.pt_name}, ${hp.pt_age} yrs, UHID: ${hp.pt_uhid}` :
                                                hp.pt_age && hp.gender_value ? `${hp.pt_name}, ${hp.pt_age} yrs ${hp.gender_value}`
                                                    : hp.pt_uhid && hp.gender_value ?
                                                        `${hp.pt_name}, UHID: ${hp.pt_uhid}, ${hp.gender_value}` :
                                                        hp.pt_uhid ? `${hp.pt_name}, UHID: ${hp.pt_uhid}` :
                                                            hp.pt_age ? `${hp.pt_name}, ${hp.pt_age} yrs` :
                                                                `${hp.pt_name}, ${hp.pt_age} yrs`
                                            ,
                                            // label: `${hp.pt_name}, ${hp.pt_age} yrs, UHID : ${hp.pt_uhid}, ${hp.gender_name}`,
                                            value: hp.ptid,
                                        }))}
                                        className={`select2-selection $`}
                                    // invalid={validation.touched.selectedPatient && validation.errors.selectedPatient}
                                    />
                                </div>
                            </Col> */}
                            {/* <Col lg={1}>

                                <div className="d-flex gap-3">
                                    <Button
                                        style={{ marginTop: 25 }}
                                        to="#"
                                        className="btn btn-sm btn-success"
                                        onClick={handleApply}
                                    >
                                        <i className="mdi mdi-check-circle" style={{ fontSize: '15px' }} id="filterrooltip" />
                                        <UncontrolledTooltip placement="top" target="filterrooltip">
                                            View
                                        </UncontrolledTooltip>
                                    </Button>


                                    <Button
                                        style={{ marginTop: 25 }}
                                        to="#"
                                        className="btn btn-sm btn-warning"
                                        onClick={() => {

                                            setSelectedDates([new Date(), new Date()])
                                            setSelectedDoctor('')
                                            setselectedhospital('')
                                            setSelectedPatient('')
                                            handleClear()

                                        }

                                        }>
                                        <i className="mdi mdi-undo" id="clearToolTip" style={{ fontSize: '15px' }} />
                                        <UncontrolledTooltip placement="top" target="clearToolTip">
                                            Clear
                                        </UncontrolledTooltip>
                                    </Button>

                                </div> */}

                            {/* 

                                                <button type="button"
                                                    style={{ marginTop: 25 }}
                                                    className="btn btn-soft-info"
                                                    onClick={handleApply}>
                                                    <i className="bx bx-cog bx-spin" />
                                                </button>
                                                <button type="button"
                                                    style={{ marginTop: 25 }}
                                                    className="btn btn-soft-info"
                                                    onClick={handleApply}>
                                                    <i className="bx bx-cog bx-spin" />
                                                </button> */}
                            {/* <div className="d-flex flex-wrap gap-2 justify-content-start">
                                                    <Button
                                                        type="button"
                                                        style={{ marginTop: 25 }}
                                                        className="btn btn-soft-info"
                                                        onClick={handleApply}
                                                    >
                                                        Apply
                                                    </Button>
                                                    <Button
                                                        type="button"
                                                        style={{ marginTop: 25 }}
                                                        className="btn btn-soft-danger"
                                                        onClick={() => {
                                                            setFilterData({
                                                                selectedHospital: 0,
                                                                selectedDoctor: 0,
                                                                selectedPatient: 0,
                                                                selectedStatus: 0,
                                                                selectedApproveStatus: 0,
                                                                selectedExamPaper: 0

                                                            })

                                                        }

                                                        }
                                                    >
                                                        Clear
                                                    </Button>
                                                </div> */}
                            {/* </Col> */}
                            {/* </Row> */}
                            <Row>

                                {/* <Col lg={3}>
                                                <div className="mb-3">
                                                    <Label>Status</Label>
                                                    <Select
                                                        placeholder="Status"
                                                        value={selectedStatus}
                                                        // ref={inputRef}
                                                        onChange={(selectedOption) => {
                                                            handleSelectStatus(selectedOption);
                                                            validation.setFieldValue("selectedStatus", selectedOption);
                                                        }}
                                                        options={pstatus.map((hp) => ({
                                                            label: hp.p_name,
                                                            value: hp.id,
                                                        }))}
                                                        className={`select2-selection ${validation.touched.selectedStatus && validation.errors.selectedStatus ? 'is-invalid' : ''}`}
                                                        invalid={validation.touched.selectedStatus && validation.errors.selectedStatus}
                                                    />
                                                    {validation.touched.selectedStatus && validation.errors.selectedStatus ? (
                                                        <FormFeedback type="invalid">{validation.errors.selectedStatus}</FormFeedback>
                                                    ) : null}
                                                </div>

                                            </Col> */}
                                {/* 
                                            <Col lg={2} >
                                                <div className="d-flex flex-wrap gap-2 justify-content-start">
                                                    <Button
                                                        type="button"
                                                        style={{ marginTop: 25 }}
                                                        className="btn btn-soft-info"
                                                        onClick={handleApply}
                                                    >
                                                        Apply
                                                    </Button>
                                                    <Button
                                                        type="button"
                                                        style={{ marginTop: 25 }}
                                                        className="btn btn-soft-danger"
                                                        onClick={() => {
                                                            setFilterData({
                                                                selectedHospital: 0,
                                                                selectedDoctor: 0,
                                                                selectedPatient: 0,
                                                                selectedStatus: 0,
                                                                selectedApproveStatus: 0,
                                                                selectedExamPaper: 0

                                                            })

                                                        }

                                                        }
                                                    >
                                                        Clear
                                                    </Button>
                                                </div>
                                            </Col> */}
                            </Row>

                            <Row>

                            </Row>
                        </Collapse>
                    </Row>
                </Form>
            </CardBody >
        </Card >


    );
}

CommonFilter.propTypes = {
    t: PropTypes.any,
    chartsData: PropTypes.any,
    onGetChartsData: PropTypes.func,
};

export default withTranslation()(CommonFilter);